import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/WaveViewer.scss';
import Tone from 'tone';

import { BlockPageScroll } from '../BlockPageScroll';
import { isMobile } from 'react-device-detect';
import { WaveData } from './Wave';

const SAVE_BUTTON_RADIUS = 6;
const SAVE_BUTTON_OFFSET = 2;
export const MAX_NUM_WAVES = 5.99; // Not exactly 5 because we need some gap after we plot the waves
let DOWN_OFFSET = 15;
let WV_WIDTH = 512; // 512, 384
let WV_HEIGHT = 288;
if (!isMobile) {
  WV_HEIGHT = 576;
  WV_WIDTH = 768;
}
const HANDLE_HEIGHT = 100;
let renderTimestamp = 0;
const LONG_PRESS_TIME = 500; // time in milliseconds
let longPressTimeout;

/* Using a tool to find 16 colors that are the most distinct from each other, I've listed the output:
 *  blue, lime, khaki, dark slate grey, fuchsia, dodger blue, brown, aqua, pink, orange, dark green,
 *  yellow, dark blue, orchid, medium spring green, red.
 *
 *  HOWEVER, the first color is the default color scheme used on other areas of the app, such as buttons,
 *  sliders, etc.
 */
const HANDLE_COLOR_OPTIONS = [
  'rgba(45, 159, 204, 0.5)',
  'rgba(0, 0, 255, 0.5)',
  'rgba(0, 255, 0, 0.5)',
  'rgba(240, 230, 140, 0.5)',
  'rgba(47, 79, 79, 0.5)',
  'rgba(255, 0, 255, 0.5)',
  'rgba(30, 144, 255, 0.5)',
  'rgba(165, 42, 42, 0.5)',
  'rgba(0, 255, 255, 0.5)',
  'rgba(255, 192, 203, 0.5)',
  'rgba(255, 165, 0, 0.5)',
  'rgba(0, 100, 0, 0.5)',
  'rgba(255, 255, 0, 0.5)',
  'rgba(0, 0, 139, 0.5)',
  'rgba(218, 112, 214, 0.5)',
  'rgba(0, 250, 154, 0.5)',
  'rgba(255, 0, 0, 0.5)'
];

// const HANDLE_EDGE_TOP_COLOR = 'rgba(240, 208, 34, 1)';
// const HANDLE_EDGE_BOTTOM_COLOR = 'rgba(3,166,120, 1)';

class WaveViewer extends Component {
  constructor() {
    super();

    this.cvRef = React.createRef();
    this.handleRef = React.createRef();

    this.draggingHandle = false;
    this.handleStartOffset = 0;
    this.draggingLeft = false;
    this.draggingRight = false;
    this.moveBtnPos = null;
    this.movingRecording = false;

    this.state = {
      draggingSelection: false,
      selection: null,
      width: WV_WIDTH,
      height: WV_HEIGHT,
      selectDrag: false, // true when user starts to drag mouse for selection
      handleHeight: HANDLE_HEIGHT,
      numSelections: 0,
      swipe: true,
      simulate: false,
      mobileselect: false,
      mobileselectEnable: false,
      pressStart: 0,
      dragMove: 0,
      isDragMove: true,
      zoomdif: 0,
      multictrl: false,
      movingSelectionOnTrack: false, // State for whether the mouse is editing the selection on the waveform
      moveStartingPoint: null, // Anchor point for moving recordings, used for calculating move distance
      recPreviewPadding: 0, // Distance between preview recording and current recording, for UI purpose
    };

    //this.preptouch = this.preptouch.bind(this)
    //this.handleButtonRelease = this.handleButtonRelease.bind(this)
  }

  /**
   * Render the initial layout of the canvas and set the default values upon mount
   */
  componentDidMount() {
    // set the canvas props
    const cv = this.cvRef.current;
    let wide = window.innerWidth*0.6;
    let heig = window.innerHeight*0.7;
    this.setState({ width: wide, height: heig });
    DOWN_OFFSET = heig * 0.02
    const ctx = cv.getContext('2d');
    ctx.lineWidth = 2;
    ctx.lineJoin = 'round';
    ctx.strokeStyle = '#fff';
    ctx.fillStyle = '#000';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.translate(0, this.state.height / 2);
    ctx.save();

    window.addEventListener('resize', this.handleResize);
    
    // force update every 100 seconds
    this.interval = setInterval(() => {
      this.setState({ forced: true })
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * @name handleResize
   * @description resizes the waveviewer dimensions on resize event.
   *              Effects: Resizes the waveviewer, then translates its coordinates appropriately
   */
  handleResize = () => {
    // console.log("resizing:" + window.innerWidth + " " + window.innerHeight);
    const cv = this.cvRef.current;
    const ctx = cv.getContext('2d');
    // Reset current transformation matrix to the identity matrix
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    let wide = window.innerWidth*0.6;
    let heig = window.innerHeight*0.8;
    // Translate to center vertical axis along midline of canvas
    ctx.translate(0, heig / 2);
    
    // set width and height in state
    this.setState({ width: wide, height: heig });
  };

  /**
   * Draws the scale points based on all the parameters, skips some points on scale when number of pts goes beyond 20 pts
   * @param {*} barHeight - Height of the bar
   * @param {*} pointHeight - Height of each on, usually lower than the bar height
   * @param {*} firstPoint - Point we want the first point to be on bar (x coordinate)
   * @param {*} initialMultiplier - Multiplier to know what text to show on scale
   * @param {*} deltaPoint - Distance between 2 points
   * @param {*} deltaMul - Difference between time on the scale
   * @param {*} cvCtx - canvas context
   * @param {*} textHeight - Height of text
   * @param {*} fillText - Fill the text
   * @returns 
   */
  drawScalePoints(barHeight,pointHeight,firstPoint,initialMultiplier,deltaPoint,deltaMul,cvCtx,textHeight,fillText = true) {
    let currentLen = firstPoint;
    let currentMul = initialMultiplier;
    let pointCount = 0;
    let approxPts = (this.state.width - firstPoint)/deltaPoint;
    let skipLevel = 0;
    if(approxPts > 20) {
      skipLevel = Math.floor(approxPts/20);
    }
    while(currentLen < this.state.width) {
      cvCtx.moveTo(currentLen,barHeight);
      if(skipLevel === 0) {
        cvCtx.lineTo(currentLen,pointHeight);
        if(fillText) {
          cvCtx.fillText(` ${currentMul}`, currentLen, textHeight);
        }
        skipLevel = Math.floor(approxPts/20);
      } else {
        skipLevel--;
      }
      
      currentLen += deltaPoint;
      currentMul += deltaMul;
      pointCount += 1;
    }
    return pointCount;
  }

  /**
   * Function  to render the scale
   */
  renderScale(audioRecorder, wvContainer) {
    const cv = this.cvRef.current;
    const ctx = cv.getContext('2d');
    const scale = wvContainer.getScale();
    const sampleRate = audioRecorder.getSampleRate();

    // # pixels for 1 second
    const pps = sampleRate * scale;

    let mul = 1;
    while (pps * mul > this.state.width / 2) mul /= 10;

    const len = pps * mul;
    let unit;
    if (mul >= 1) {
      unit = 's';
    } else if (mul >= 0.001) {
      unit = 'ms';
    } else {
      unit = 'µs';
    }
    while (mul < 1) mul *= 1000;

    let barHeight = -0.45* this.state.height;
    let pointHeight = -0.43* this.state.height;
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, barHeight, this.state.width, 2);
    ctx.beginPath();
    ctx.strokeStyle = "#fff";
    let cntStrokesOnScale = this.drawScalePoints(barHeight,pointHeight,len,mul,len,mul,ctx,0.95*pointHeight);
    if(cntStrokesOnScale <= 4) {
      this.drawScalePoints(barHeight,pointHeight,len/2,mul/2,len,mul,ctx,0.95*pointHeight);
    } else if(cntStrokesOnScale <= 8) {
      this.drawScalePoints(barHeight,pointHeight - 0.01*this.state.height,len/2,mul/2,len,mul,ctx,undefined,false);
    }
    ctx.stroke();
    ctx.font="15px sans-serif"
    ctx.fillText(`${unit}`,0.5*this.state.width, 0.95*pointHeight)
    ctx.font="10px sans-serif"
  }

  /**
   * Draws the graph using plotTimeData function, also renders the bar which tells the location till which audio has been played
   * @param {*} audioRecorder 
   * @param {*} wvContainer 
   * @returns 
   */
  drawGraph(audioRecorder, wvContainer) {
    const start = wvContainer.start(false, wvContainer.getEnd());
    const cv = this.cvRef.current;
    const handle = this.handleRef.current;
    const ctx = cv.getContext('2d');
    const handleCtx = handle.getContext('2d');
    const sampleRate = audioRecorder.getSampleRate();
    const startIndex = wvContainer.getStart(cv);
    // clear canvas with black at every instance of drawGraph
    ctx.fillStyle = "#000000";
    handleCtx.fillStyle = "#000000";
    ctx.fillRect(0, -cv.height / 2, cv.width, cv.height);
    handleCtx.fillRect(0, 0, handle.width, handle.height);
    
    // if any clip is selected, highlight it
    if (audioRecorder.getSelectedClipId() !== null){
      ctx.fillStyle = "#3B3B3B";
      const rectangleHeight = this.state.height/MAX_NUM_WAVES; //TODO: hard coded, should be dynamic based on number of clips
      const selectedClipId = audioRecorder.getSelectedClipId();
      const selectedClipIndex = Array.from(audioRecorder.getRawWaveMap()).map(item => item[0]).indexOf(selectedClipId); //TODO: change this using xPos and yPos
      const clipYAxis = selectedClipIndex * rectangleHeight; //TODO: make is more accurate
      ctx.fillRect(0, DOWN_OFFSET -cv.height / 2 + clipYAxis, cv.width, rectangleHeight);
      ctx.fillStyle = "#000000";
    }

    

    // do not render if audioData is empty
    if (!audioRecorder.getAudioDataLength() && audioRecorder.getRawAudioData()?.length === 0) {
      return;
    }

    // plot the signal data obtained
    this.plotTimeData(audioRecorder, wvContainer, startIndex);

    // draw the bar for selectionPlaying if selection length is longer than 100 milliseconds
    if (wvContainer.getDisplayBar()) {
      ctx.beginPath();
      ctx.strokeStyle = '#fff';
       //TODO: hard coded, should be dynamic based on number of clips
      const selectedClipIndex = audioRecorder.getSelectedClipIndex();
      let clipYAxis,rectangleHeight;
      if (selectedClipIndex >= audioRecorder.getWaveMapSize() || wvContainer.getPointSelectionFlag() || wvContainer.getSelectionLength(audioRecorder.getSampleRate()) === 0) {
        clipYAxis = 0
        rectangleHeight = audioRecorder.getWaveMapSize()*this.state.height/MAX_NUM_WAVES;
      } else {
        rectangleHeight = this.state.height/MAX_NUM_WAVES;
        clipYAxis = selectedClipIndex * rectangleHeight; //TODO: make is more accurate
      }
      
      const x =
        wvContainer.getScale() *
        (wvContainer.state.prevSelection.start -
          start +
          (wvContainer.getCurrentSelectionPlayTime() / 1000) * sampleRate);

      ctx.moveTo(x,DOWN_OFFSET -cv.height / 2 + clipYAxis);
      ctx.lineTo(x,DOWN_OFFSET -cv.height / 2 + clipYAxis + rectangleHeight);
      ctx.stroke();
    }
    if(audioRecorder.getWaveMapSize() > 0 || audioRecorder.isRecording()) {
      this.renderScale(audioRecorder, wvContainer);
    }
  }

  /**
   * Goes through all the waves in the wave map and plots the data individually using plotPrevData (ignore the bad name)
   * @param {*} audioRecorder 
   * @param {*} wvContainer 
   * @param {*} startIndex 
   */
  plotTimeData(audioRecorder, wvContainer, startIndex) {
    let yOffset = DOWN_OFFSET -0.85*(this.state.height/MAX_NUM_WAVES)*((MAX_NUM_WAVES - 1)/2); // init offset for the wave line, affects the y axis of the wave. TODO: make this dynamic
    const sampleRate = audioRecorder.getSampleRate();

    const currAudioData = audioRecorder.getRawAudioData(); 
    const waveMap = audioRecorder.getRawWaveMap();
    const colors = ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#808000", "#800080"];
    const recordingColor = "#FFFFFF"

    // plot all previous audio in waveMap
    waveMap.forEach(item => {
      let audioData = (audioRecorder.isRecording() && item.id === audioRecorder.getRecordingClipId()) ? audioRecorder.getRawAudioData() : item.audioData // Use buffers for plotting when recording because timeData doesn't update real-time
      yOffset = this.plotPrevData(audioData, sampleRate, wvContainer, startIndex, yOffset, colors[item.id % colors.length])
    })
    //plot the current recording audio at bottom
    if (audioRecorder.isRecording() && audioRecorder.getRecordingClipId() === null) {
      this.plotPrevData(currAudioData, sampleRate, wvContainer, startIndex, yOffset, recordingColor)
    }
  }

  // plot the audio wave in audioData, returns the incremented yOffset for the next wave
  // This uses WaveData class defined in Wave.js to get svg required to plot
  plotPrevData(audioData, sampleRate, wvContainer, startIndex, yOffset, waveColor) {
    // Use SVG syntax to improve performance instead of ctx.fillRect() every time 
    // SVG path data: https://www.w3.org/TR/SVG2/paths.html
    // let rectangles = ""; // SVG strings to be filled in
    // let lines = "";
    const cv = this.cvRef.current;
    const ctx = cv.getContext('2d');
    const end = wvContainer.getEnd();
    const scale = wvContainer.getScale();

    const myWave = new WaveData(1,audioData,false,undefined,undefined,false);
    const [rectangles,lines] = myWave.getSVGForWave(scale, startIndex, end, yOffset, this.state.height/MAX_NUM_WAVES);
    ctx.lineWidth = 2;
    ctx.fillStyle = waveColor;
    ctx.strokeStyle = waveColor;
    ctx.fill(new Path2D(rectangles));
    ctx.stroke(new Path2D(lines));
    yOffset += this.state.height/MAX_NUM_WAVES;
    return yOffset;
  }

  drawUserSelection(audioRecorder, wvContainer) {
    const { selectDrag } = this.state;
    const pointSelection = wvContainer.getPointSelectionFlag();
    const cv = this.cvRef.current;
    const handle = this.handleRef.current;
    const ctx = cv.getContext('2d');
    const handleCtx = handle.getContext('2d');
    const selection = wvContainer.getSelection();
    const end = wvContainer.getEnd();
    const scale = wvContainer.getScale();
    const start = wvContainer.getStart(cv);
    const selectedClipId = audioRecorder.getSelectedClipId();
    
    /* Code to draw the move button for the selected clip */
    if (selectedClipId !== null && 
      audioRecorder.getRawWaveMap().has(selectedClipId) &&
      !audioRecorder.isRecording() && 
      !(!pointSelection && selection.end - selection.start > 1)
      ){
      ctx.beginPath();
        
      // Set button style
      const selectedClipIndex = Array.from(audioRecorder.getRawWaveMap()).map(item => item[0]).indexOf(selectedClipId); //TODO: change this using xPos and yPos
      ctx.fillStyle = "rgba(255,255,255,0.8)"; // semi-transparent white button
      const rectangleHeight = this.state.height/(MAX_NUM_WAVES); //TODO: hard coded, should be dynamic based on number of clips
      const clipYAxis = selectedClipIndex * rectangleHeight; //TODO: make is more accurate

      // Get button position
      const { audioData } = audioRecorder.getSelectedClipAndData();  
      let signalData = audioData.slice(start, end);
      let xLeft = signalData.findIndex(val => val !== 0) * scale; // find the left side of recording
      let xRight = Math.min(signalData.length * scale, cv.width); // find the right side of recording
      let xOffset = (xLeft + xRight) / 2; // put the button at the middle of recording
      let xPreviewOffset = this.state.recPreviewPadding;
      // const yOffset = rectangleHeight / 2 + 9; //TODO: for middle of wave, hard coded 9. Should figure out why
      let yOffset = rectangleHeight - 10;
      // if(MAX_NUM_WAVES===1) yOffset -= 0.1*this.state.height;

      // Draw button
      ctx.arc(xOffset + xPreviewOffset, DOWN_OFFSET - cv.height / 2 + clipYAxis + yOffset, SAVE_BUTTON_RADIUS, 0, 2 * Math.PI);
      ctx.fill();
      ctx.fillStyle = "#000000";
      // Save the button position for future use: zero is at top left
      this.moveBtnPos = {x: xOffset, y: clipYAxis + yOffset};
    }else{
      this.moveBtnPos = null;
    }

    /* Code to render the selection */
    if (!pointSelection || selectDrag) {
      // selection style
      ctx.fillStyle = 'rgba(255, 255, 255, 0.2)';
    } else {
      // vertical line (click no drag) style has invisible selection box
      ctx.fillStyle = 'rgba(240,208,34, 0)';
    }
    // TODO: for multiple selection handles, use the next color in the handle_color_options list
    handleCtx.fillStyle = HANDLE_COLOR_OPTIONS[0];

    const rectangleHeight = this.state.height/MAX_NUM_WAVES; //TODO: hard coded, should be dynamic based on number of clips
    const selectedClipIndex = Array.from(audioRecorder.getRawWaveMap()).map(item => item[0]).indexOf(selectedClipId); //TODO: change this using xPos and yPos
    const clipYAxis = selectedClipIndex * rectangleHeight; //TODO: make is more accurate
    
    const myWave = new WaveData(1,undefined, selectedClipId !== null, selection.start, selection.end, false);
    if ((selectDrag || pointSelection)) {
      myWave.updateCtxUserSelection(ctx, start, end, scale, rectangleHeight, DOWN_OFFSET - cv.height / 2 + clipYAxis);
    }
    //yellow vertical line for point selection
    if (pointSelection && !selectDrag  && selection.start > 0) {
      // console.log('point selection made');
      ctx.strokeStyle = 'rgba(240, 208, 34, 0.8)';
      ctx.lineWidth = 2;
      ctx.beginPath();
      ctx.moveTo(((selection.start + selection.end)/2 - start) * scale, cv.height);
      ctx.lineTo(((selection.start + selection.end)/2- start) * scale, -cv.height);
      ctx.stroke();
      ctx.strokeStyle = '#fff';
    }
  }

  prepmove(e, arContainer, wvContainer) {
    const hasRecorded = arContainer.hasRecorded();
    if (this.state.swipe && hasRecorded) {
      arContainer.touchmove(
        e,
        wvContainer.start(false, wvContainer.getEnd()),
        wvContainer.getScale(),
        this.state.simulate,this.state.width
      );
      //return;
    }
    this.buttonPressTimer = setTimeout(() => this.touchmove(e, arContainer, wvContainer), 1000);
  }

  touchdown(e, arContainer, wvContainer) {
    const hasRecorded = arContainer.hasRecorded();
    if (this.state.swipe && hasRecorded) {
      arContainer.touchdown(
        e,
        wvContainer.start(false, wvContainer.getEnd()),
        wvContainer.getScale(),
        this.state.simulate
      );
      //return;
    }
    if (this.state.mobileselectEnable) {
      this.setState({ mobileselect: true });
    }
    //clearTimeout(this.buttonPressTimer);
    this.setState({ mobileselectEnable: true });
    this.buttonPressTimer = setTimeout(() => this.setState({ mobileselectEnable: false }), 400);

    // always make a new selection when user click on the main canvas(not the handle)
    wvContainer.setNewSelection(true);

    // clear display bar
    wvContainer.setDisplayBar(false);

    // clear manual selection
    wvContainer.setManualPtSelection(true);

    const cv = this.cvRef.current;

    // update the user selection in the wv container
    //const offset = e.nativeEvent.offsetX;

    const offset = e.touches[0].clientX - (window.innerWidth - this.state.width) / 2;
    if (e.touches.length > 1) {
      const newEnd = e.touches[1].clientX - (window.innerWidth - this.state.width) / 2;
      this.setState({
        zoomdif: Math.abs(newEnd - offset),
        selection: null,
        dragMove: newEnd,
        multictrl: true,
        draggingSelection: true,
        selectDrag: false,
        pressStart: offset
      });
      wvContainer.updateSelection(offset, cv, 'multiend', arContainer);
    } else {
      const newSelection = wvContainer.updateSelection(offset, cv, 'mousedown', arContainer);
      this.setState({
        selection: newSelection,
        draggingSelection: true,
        selectDrag: false,
        pressStart: offset      
      });
    }

    arContainer.resetResample();
    arContainer.resetAmplitude();
    arContainer.setResetLengthDisplay(true);

    //wvContainer.updateSelection(0, null, 'mouseup');
    /*      
    wvContainer.setSelectionLengthString(
      wvContainer.getSelectionLength(arContainer.getSampleRate())
     );
     */
  }

  // helper function to check if the mouse is in the current selection, called in mouseDown
  CheckMouseInSelection(e, arContainer, wvContainer) {
    const cv = this.cvRef.current;
    const selection = wvContainer.getSelection();
    const scale = wvContainer.getScale();
    const start = wvContainer.getStart(cv);
    const startPixel = (selection.start - start) * scale;
    const endPixel = (selection.end - start) * scale;
    const offset = e.nativeEvent.offsetX;

    //below are copied from parameter of fillRect called within drawUserSelection()
    const rectangleHeight = this.state.height/MAX_NUM_WAVES; //TODO: hard coded, should be dynamic based on number of clips
    if(arContainer.getSelectedClipId() === null){
      return false;
    }
    const clipIndex = arContainer.getSelectedClipIndex();
    const clipYAxis = clipIndex * rectangleHeight; //TODO: make is more accurate
    const rectX = (selection.start - start) * scale;
    // const rectY = -cv.height / 2 + clipYAxis;
    const rectW = (selection.end - selection.start) * scale;
    // check if the mouse is in the current selection
    const rect = cv.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const offsetForSelectingEdge = 5; // in pixels

    if (x >= rectX - offsetForSelectingEdge && x <= rectX + rectW + offsetForSelectingEdge 
      && y >= clipYAxis && y <= clipYAxis + rectangleHeight &&
      !wvContainer.getPointSelectionFlag() && selection.end - selection.start > 1) {
        // change cursor
        if (Math.abs(startPixel - offset) < offsetForSelectingEdge || 
          Math.abs(endPixel - offset) < offsetForSelectingEdge) {
          document.getElementById('wave-canvas').style.cursor = 'col-resize';
        }else{
          document.getElementById('wave-canvas').style.cursor = this.state.movingSelectionOnTrack ? 'grabbing' : 'grab';   
        }
        return true;
    }else {
      if(this.movingRecording !== true) {
        document.getElementById('wave-canvas').style.cursor = '';
      }
      return false;
    }
  }

  /**
   * After checking whether the mouse is on selection, check whether the mouse is on the move button
   * @returns True if mouse on button, false if not
   */
  checkMouseOnMoveBtn(e){
    const cv = this.cvRef.current;
    const rect = cv.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    if (this.moveBtnPos != null &&
      Math.abs(x - this.moveBtnPos.x) <= SAVE_BUTTON_RADIUS + SAVE_BUTTON_OFFSET && 
      Math.abs(y - this.moveBtnPos.y) <= SAVE_BUTTON_RADIUS + SAVE_BUTTON_OFFSET) {
        document.getElementById('wave-canvas').style.cursor = 'grab';
        return true;
    }else {
      return false;
    }
  }

  mousedown(e, arContainer, wvContainer) {
    console.log("handle mouse down upar");
    // Check whether is moving the selection, moving the recording, or neither
    if (this.CheckMouseInSelection(e, arContainer, wvContainer)) {
      this.setState({movingSelectionOnTrack: true})
      this.handlemousedown(e, arContainer, wvContainer);
      return;
    }else if (this.checkMouseOnMoveBtn(e, arContainer, wvContainer)) {
      this.mousedownOnBtn(e.nativeEvent.offsetX, arContainer, wvContainer)
      return;
    }

    const hasRecorded = arContainer.hasRecorded();
    if (this.state.swipe && hasRecorded) {
      arContainer.mousedown(
        e,
        wvContainer.start(false, wvContainer.getEnd()),
        wvContainer.getScale(),
        this.state.simulate,
        this.cvRef.current
      );
      //return;
    }

    // always make a new selection when user click on the main canvas(not the handle)
    wvContainer.setNewSelection(true);

    // clear display bar
    wvContainer.setDisplayBar(false);

    // clear manual selection
    wvContainer.setManualPtSelection(true);

    const cv = this.cvRef.current;

    // update the user selection in the wv container
    const offset = e.nativeEvent.offsetX;
    //const offset = e.touches[0].clientX;

    const newSelection = wvContainer.updateSelection(offset, cv, 'mousedown', arContainer);

    this.setState({
      draggingSelection: true,
      selection: newSelection,
      selectDrag: false
    });

    arContainer.resetResample();
    arContainer.resetAmplitude();
    arContainer.setResetLengthDisplay(true);
    // Start the long press timeout
    longPressTimeout = setTimeout(() => {
      console.log("Mouse down on button start");
      wvContainer.updateSelection(0, null, 'mouseup');
      this.mousedownOnBtn(offset, arContainer, wvContainer);
    }, LONG_PRESS_TIME);
  }

  /**
   * @description Handles the mousedown event for clicking down on moving button
   * @param {*} e Keyboard and mouse event
   * @param {*} arContainer 
   * @param {*} wvContainer 
   */
  mousedownOnBtn(offset, arContainer, wvContainer){

    document.getElementById('wave-canvas').style.cursor = 'grab';

    // Clear all selections
    wvContainer.setPointSelectionFlag(false);
    wvContainer.setManualPtSelection(true);
    wvContainer.setNewSelection(true);
    wvContainer.clearSelection();

    // Reset container settings
    this.movingRecording = true;
    wvContainer.setKeepPlayingToOldEnd(false);
    arContainer.resetResample();
    arContainer.resetAmplitude();
    this.setState({
      selection: null,
      selectDrag: false, 
      moveStartingPoint: offset,
    });
  }

  /**
   * @description Handles the mousemove event for dragging the moving button
   * @param {*} e Keyboard and mouse event
   * @param {*} arContainer 
   * @param {*} wvContainer 
   */
  mousemoveOnBtn(e, arContainer, wvContainer){
    const moveLength = e.nativeEvent.offsetX - this.state.moveStartingPoint;
    const mvlen = (moveLength) / wvContainer.getScale();
    console.log("mvlen", mvlen);
    if(true) {
      arContainer.moveRecording(mvlen, wvContainer);
      this.setState({
        moveStartingPoint: e.nativeEvent.offsetX
      })
    }
    
    this.setState({
      recPreviewPadding: moveLength
    });
  }

  /**
   * @description Handles the mouseup event for lifting mouseclick on moving button
   * @param {*} e Keyboard and mouse event
   * @param {*} arContainer 
   * @param {*} wvContainer 
   */
  mouseupOnBtn(e, arContainer, wvContainer, mContainer){
    // Move wave
    // const startingPt = this.state.moveStartingPoint;
    // const offset = e.nativeEvent.offsetX;
    // const moveLength = (offset - startingPt) / wvContainer.getScale();
    // if (moveLength !== 0){
    //   console.log("move length = ", moveLength)
    //   arContainer.moveRecording(moveLength, wvContainer);
    //   mContainer.takeSnapshot(arContainer, wvContainer);
    // }
    // Reset everything
    document.getElementById('wave-canvas').style.cursor = '';
    wvContainer.clearSelection();
    wvContainer.setPointSelectionFlag(false);
    wvContainer.setManualPtSelection(false);
    
    this.movingRecording = false;
    this.draggingHandle = false;
    this.draggingLeft = false;
    this.draggingRight = false;
    this.setState({
      selection: null,
      selectDrag: false,
      moveStartingPoint: null,
      recPreviewPadding: 0,
    });
  }

  touchmove(e, audioRecorder, wvContainer) {
    const cv = this.cvRef.current;
    const totalSamplePoints = audioRecorder.getTotalSamplePoints();
    const recordingSamplePoints = audioRecorder.getRecordingSamplePoints();
    const isRecording = audioRecorder.isRecording();
    //const ref = wvContainer.getCanvasReference();
    const offset = e.touches[0].clientX  - (window.innerWidth - this.state.width) / 2;
    if (e.touches.length > 1) {
      const newEnd = e.touches[1].clientX - (window.innerWidth - this.state.width) / 2;
      const center =
        (e.touches[0].clientX + e.touches[1].clientX) / 2 - (window.innerWidth - this.state.width) / 2;
      if (this.state.isDragMove) {
        wvContainer.skip(totalSamplePoints, this.state.dragMove - newEnd);
      }

      let posdif = Math.abs(newEnd - offset);
      if (posdif - this.state.zoomdif >= window.innerWidth / 60) { // 20
        // original: wvContainer.zoom(totalSamplePoints, false, 15, cv, center); // 15
        wvContainer.zoom(totalSamplePoints, recordingSamplePoints, isRecording, -15, cv, center); // 15
      } else if (this.state.zoomdif - posdif >= window.innerWidth / 60) {
        // 20
        wvContainer.zoom(totalSamplePoints, recordingSamplePoints, isRecording, 15, cv, center); // -15
      }
        this.setState({
          zoomdif: posdif,
          isDragMove: false,
          dragMove: newEnd,
          multictrl: true  
        });
      return;
    }

    const hasRecorded = audioRecorder.hasRecorded();
    if (this.state.swipe && hasRecorded) {
      audioRecorder.touchmove(
        e,
        wvContainer.start(false, wvContainer.getEnd()),
        wvContainer.getScale(),
        this.state.simulate
      );
      //return;
    }

    //const { draggingSelection } = this.state;
    //const offset = e.touches[0].clientX  - (window.innerWidth - WV_WIDTH) / 2;

    if (e.touches.length === 1) {
      if (!this.state.mobileselect) {
        const newSelection = wvContainer.updateSelection(offset, cv, 'pointmove');
        this.setState({
          selection: newSelection
        });

        wvContainer.setSelectionLengthString(
          wvContainer.getSelectionLength(audioRecorder.getSampleRate())
        );
      } // return if mousemove not on selection dragging canvas

      // Update the selection start and end as mouse moves
      //const offset = e.nativeEvent.offsetX;
      else {
        const newSelection = wvContainer.updateSelection(offset, cv, 'mousemove');
        this.setState({
          selection: newSelection,
          selectDrag: true
        });

        wvContainer.setSelectionLengthString(
          wvContainer.getSelectionLength(audioRecorder.getSampleRate())
        );
      }
    }
  }

  mousemove(e, audioRecorder, wvContainer) {
    this.CheckMouseInSelection(e, audioRecorder, wvContainer);
    this.checkMouseOnMoveBtn(e, audioRecorder, wvContainer);
    if (this.state.movingSelectionOnTrack === true){
      this.handlemousemove(e, audioRecorder, wvContainer);
      return;
    }else if (this.movingRecording === true) {
      this.mousemoveOnBtn(e, audioRecorder, wvContainer)
      return;
    }

    if(this.movingRecording === false) {
      clearTimeout(longPressTimeout);
    }

    const hasRecorded = audioRecorder.hasRecorded();
    if (this.state.swipe && hasRecorded) {
      audioRecorder.mousemove(
        e,
        wvContainer.start(false, wvContainer.getEnd()),
        wvContainer.getScale(),
        this.state.simulate
      );
      //return;
    }

    const cv = this.cvRef.current;
    const { draggingSelection } = this.state;
    if (!draggingSelection) return; // return if mousemove not on selection dragging canvas

    // Update the selection start and end as mouse moves
    const offset = e.nativeEvent.offsetX;

    const newSelection = wvContainer.updateSelection(offset, cv, 'mousemove');
    this.setState({
      selection: newSelection,
      selectDrag: true
    });

    wvContainer.setSelectionLengthString(
      wvContainer.getSelectionLength(audioRecorder.getSampleRate())
    );
  }

  mouseup(e, audioRecorder, wvContainer, mContainer) {
    clearTimeout(longPressTimeout);
    if (this.state.movingSelectionOnTrack === true){
      this.handlemouseup(e, audioRecorder, wvContainer);
      this.setState({movingSelectionOnTrack: false})
      return;
    }else if (this.movingRecording === true) {
      this.mouseupOnBtn(e, audioRecorder, wvContainer, mContainer);
    }

    this.setState({ 
      mobileselect: false,
      dragMove: 0
    });
    let pSelect = false; // indicates if the selection was a point selection

    // If clearing selection or selection was dragged, set pSelect to false. Otherwise it is a point selection
    if (
      Math.abs(wvContainer.getSelection().end- wvContainer.getSelection().start) < Math.min(0.18, wvContainer.getScale()) ||
      this.state.selectDrag
    ) {
      // Clear the selection or dragged selection
      pSelect = false;
    } else {
      // point selection
      pSelect = true;
    }

    //if (this.state.isDragMove) {wvContainer.updateSelection(0, null, 'mouseup');}

    wvContainer.setPointSelectionFlag(pSelect);

    //console.log(pSelect);
    //if (this.state.multictrl) {wvContainer.updateSelection(0, null, 'multiend');} // values of offset and cv does not matter in this function call
    wvContainer.updateSelection(0, null, 'mouseup');
    this.setState({
      draggingSelection: false,
      isDragMove: true
      //multictrl: false,
    });
    this.movingRecording = false;
  }

  handlemousedown(e, arContainer, wvContainer) {
    const cv = this.cvRef.current;
    // const handle = this.handleRef.current;
    const offset = e.nativeEvent.offsetX;
    // const ypos = e.nativeEvent.offsetY;
    this.draggingHandle = true;

    this.handleStartOffset = offset;

    const start = wvContainer.getStart(cv);
    const selection = wvContainer.getSelection();
    const scale = wvContainer.getScale();
    const startPixel = (selection.start - start) * scale;
    const endPixel = (selection.end - start) * scale;

    let keepPlayingUntilOldEnd = true;

    const offsetForSelectingEdge = 5; // in pixels

    if (Math.abs(startPixel - offset) < offsetForSelectingEdge) {
      this.draggingLeft = true;
      keepPlayingUntilOldEnd = false;
    } else if (Math.abs(endPixel - offset) < offsetForSelectingEdge) {
      this.draggingRight = true;
      keepPlayingUntilOldEnd = false;
    }
    wvContainer.setKeepPlayingToOldEnd(keepPlayingUntilOldEnd);
    arContainer.resetResample();
    arContainer.resetAmplitude();
  }

  handlemousemove(e, audioRecorder, wvContainer) {
    /* Handle cursor style changes. Calculates location of mouse in relation to selection and changes the cursor type accordingly */
    const cv = this.cvRef.current;
    const offset = e.nativeEvent.offsetX;
    const ypos = e.nativeEvent.offsetY;
    const handle = this.handleRef.current;
    const selection = wvContainer.getSelection();

    if (selection.start !== selection.end) {
      const start = wvContainer.getStart(cv);
      const scale = wvContainer.getScale();
      const startPixel = (selection.start - start) * scale;
      const endPixel = (selection.end - start) * scale;
      const offsetForSelectingEdge = 5; // in pixels

      // console.log(startPixel + " " + offset + " " + offsetForSelectingEdge);

      // change cursors on edges
      if (
        Math.abs(startPixel - offset) < offsetForSelectingEdge ||
        Math.abs(endPixel - offset) < offsetForSelectingEdge
      ) {
        // upper half cursor
        if (ypos < handle.height / 2) {
          document.getElementById('selection-editor').style.cursor = 'col-resize';
        }
        // lower half cursor
        else {
          document.getElementById('selection-editor').style.cursor = '';
        }
      }
      // change cursors on outside space (not on selection)
      else if (
        startPixel - offsetForSelectingEdge > offset ||
        endPixel + offsetForSelectingEdge < offset
      ) {
        document.getElementById('selection-editor').style.cursor = 'move';
      }
      // change cursor to normal
      else {
        document.getElementById('selection-editor').style.cursor = '';
      }
    } else {
      // no selection, use default pointing cursor
      document.getElementById('selection-editor').style.cursor = '';
    }

    /* End cursor changes */

    if (!this.draggingHandle) return; // return if mousemove not on selection dragging canvas

    // Update the selection start and end as mouse moves
    // const offset = e.nativeEvent.offsetX;

    const offsetInSamples = (offset - this.handleStartOffset) / wvContainer.getScale();

    this.handleStartOffset = offset;

    // deep copy the updated selection
    const updateSelection = {};
    Object.assign(updateSelection, wvContainer.getSelection());
    let isTranslating = false;

    // update the selection based on if the drag is on the left edge or the right
    // edge or the middle
    if (this.draggingLeft) {
      updateSelection.start += offsetInSamples;
      wvContainer.setStartTime(
        wvContainer.getStartTime() + (offsetInSamples * 1000) / audioRecorder.getSampleRate()
      );
      // Update playing bar position if it's displayed
      if(wvContainer.getDisplayBar()){
        wvContainer.setCurrentSelectionPlayTime(Tone.context.now()*1000 - wvContainer.getStartTime());
      }
      document.getElementById('selection-editor').style.cursor = 'col-resize'; // persistent cursor type while dragging
    } else if (this.draggingRight) {
      
      updateSelection.end += offsetInSamples;
      document.getElementById('selection-editor').style.cursor = 'col-resize'; // persistent cursor type while dragging
    } else {
      updateSelection.start += offsetInSamples;
      updateSelection.end += offsetInSamples;
      isTranslating = true;
      document.getElementById('selection-editor').style.cursor = '';
    }

    // check if the new selection is valid (within bounds and not reversed)
    if (
      updateSelection.start > 0 &&
      updateSelection.end < wvContainer.getEnd() &&
      updateSelection.start < updateSelection.end
    ) {
      this.setState({
        selection: updateSelection
      });
      wvContainer.setSelection(updateSelection);
      wvContainer.setSelectionLengthString(
        wvContainer.getSelectionLength(audioRecorder.getSampleRate())
      );
    }

    wvContainer.setIsTranslating(isTranslating);
  }

  handlemouseup(wvContainer) {
    this.draggingHandle = false;
    this.draggingLeft = false;
    this.draggingRight = false;
    this.movingRecording = false;
    document.getElementById('selection-editor').style.cursor = '';
  }

  mousewheel(e, audioRecorder, wvContainer) {
    const cv = this.cvRef.current;
    const totalSamplePoints = audioRecorder.getTotalSamplePoints();
    const recordingSamplePoints = audioRecorder.getRecordingSamplePoints();
    const isRecording = audioRecorder.isRecording();

    // if the audioData is empty or if the length of the audioData is zero, return
    if (!audioRecorder.getRawAudioData() || !audioRecorder.getAudioDataLength()) {
      return;
    }

    // get the deltas of the e
    const { deltaX, deltaY } = e;

    const screenRect = cv.getBoundingClientRect();
    //const xPosToZoom = e.clientX - screenRect.left;
    const xPosToZoom = e.clientX - screenRect.left;

    // lock scrolling to a single axis

    if (Math.abs(deltaX) >= Math.abs(deltaY)) {
      wvContainer.skip(totalSamplePoints, deltaX, cv);
    }

    if (Math.abs(deltaY) >= Math.abs(deltaX)) {
      wvContainer.zoom(totalSamplePoints, recordingSamplePoints, isRecording, -deltaY, cv, xPosToZoom);
    }
  }

  // Main render loop
  render() {
    const { audioRecorder, wvContainer, mContainer } = this.props;
    if (this.cvRef.current) {
      /* TODO: Make the WaveViewer busy wait up until the canvas reference is 
        generated and stored in container
      */
      if (!wvContainer.getCanvasReference()) {
        wvContainer.setCanvasReference(this.cvRef.current);
      }
      // Make sure canvas is translated correctly
      const cv = this.cvRef.current;
      const ctx = cv.getContext('2d');
      ctx.setTransform(1, 0, 0, 1, 0, 0); // resets transform matrix so that it can be translated
      // Translate to center vertical axis along midline of canvas (0.9 = 90% of screen height; 10% is top menu)
      ctx.translate(0, ((this.state.height / wvContainer.getNumViewers()) * 0.9) / 2); 

      // Allow render only after some time (ms)
      let currTime = Date.now()
      if (currTime - renderTimestamp >= 50) {
        renderTimestamp = currTime;
        // console.log("DRAW: ", currTime) //DEBUG
        this.drawGraph(audioRecorder, wvContainer);
        this.drawUserSelection(audioRecorder, wvContainer);
      }
    }

    return (
      <div>
        {!isMobile ? (
          <div className="wave-viewer">        
          <BlockPageScroll>
            <canvas
              //className="wave-canvas"
              id="wave-canvas"
              ref={this.cvRef}
              // width={512}
              // height={512}
              width={this.state.width}
              height={(this.state.height / wvContainer.getNumViewers()) * 0.9}
              onWheel={e => this.mousewheel(e, audioRecorder, wvContainer)}
              onMouseDown={e => this.mousedown(e, audioRecorder, wvContainer)}
              onMouseMove={e => this.mousemove(e, audioRecorder, wvContainer)}
              onMouseUp={e => this.mouseup(e, audioRecorder, wvContainer, mContainer)}
              onMouseOut={e => this.mouseup(e, audioRecorder, wvContainer, mContainer)}
              onTouchStart={e => this.touchdown(e, audioRecorder, wvContainer)}
              //ziyan: onTouchStart={e => this.touchdown(e, audioRecorder, wvContainer)} for up
              onTouchMove={e => this.touchmove(e, audioRecorder, wvContainer)}
              //ziyan: onTouchMove={e => this.touchmove(e, audioRecorder, wvContainer)} for up
              onTouchEnd={e => this.mouseup(e, audioRecorder, wvContainer)}
              //ziyan: onTouchEnd={e => this.mouseup(e, audioRecorder, wvContainer)} for up
            ></canvas>
            <div>
              <canvas 
                // selection editor is hidden in multiWaveViewer version via WaveViewer.scss file
                //className="wave-canvas"
                id="selection-editor"
                ref={this.handleRef}
                width={this.state.width}
                height={this.state.handleHeight}
                onWheel={e => this.mousewheel(e, audioRecorder, wvContainer)}
                onMouseDown={e => this.handlemousedown(e, audioRecorder, wvContainer)}
                onMouseMove={e => this.handlemousemove(e, audioRecorder, wvContainer)}
                onMouseUp={() => this.handlemouseup(wvContainer)}
                onMouseOut={() => this.handlemouseup(wvContainer)}
                onTouchStart={e => this.handlemousedown(e, audioRecorder, wvContainer)}
                onTouchMove={e => this.handlemousemove(e, audioRecorder, wvContainer)}
                onTouchEnd={() => this.handlemouseup(wvContainer)}
              ></canvas>
            </div>
          </BlockPageScroll>
          </div>
        ): (
        <div className="wave-viewer">
        <BlockPageScroll>
          <canvas
            //className="wave-canvas"
            ref={this.cvRef}
            // width={512}
            // height={512}
            width={this.state.width}
            height={(this.state.height / wvContainer.getNumViewers()) * 0.9}
            onWheel={e => this.mousewheel(e, audioRecorder, wvContainer)}
            //onMouseDown={e => this.mousedown(e, audioRecorder, wvContainer)}
            //onMouseMove={e => this.mousemove(e, audioRecorder, wvContainer)}

            onTouchStart={e => this.touchdown(e, audioRecorder, wvContainer)}
            //ziyan: onTouchStart={e => this.touchdown(e, audioRecorder, wvContainer)} for up
            onTouchMove={e => this.touchmove(e, audioRecorder, wvContainer)}
            //ziyan: onTouchMove={e => this.touchmove(e, audioRecorder, wvContainer)} for up
            onTouchEnd={e => this.mouseup(e, audioRecorder, wvContainer, mContainer)}
            //ziyan: onTouchEnd={e => this.mouseup(e, audioRecorder, wvContainer)} for up
            //onMouseUp={() => this.mouseup(wvContainer)}
            //onMouseOut={() => this.mouseup(wvContainer)}
          ></canvas>
          <div>
            <canvas
              //className="wave-canvas"
              id="selection-editor"
              ref={this.handleRef}
              width={this.state.width}
              height={this.state.handleHeight}
              onWheel={e => this.mousewheel(e, audioRecorder, wvContainer)}
              onTouchStart={e => this.handlemousedown(e, audioRecorder, wvContainer)}
              onTouchMove={e => this.handlemousemove(e, audioRecorder, wvContainer)}
              onTouchEnd={() => this.handlemouseup(wvContainer)}
              //onMouseDown={e => this.handlemousedown(e, audioRecorder, wvContainer)}
              //onMouseMove={e => this.handlemousemove(e, audioRecorder, wvContainer)}
              //onMouseUp={() => this.handlemouseup(wvContainer)}
              //onMouseOut={() => this.handlemouseup(wvContainer)}
            ></canvas>
          </div>
        </BlockPageScroll>
        </div>
        )}
      </div>
    );
  }
}

export default WaveViewer;

WaveViewer.propTypes = {
  audioRecorder: PropTypes.object,
  wvContainer: PropTypes.object
};
