/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import { Button, Menu, Dropdown, Icon, Popup, Modal } from 'semantic-ui-react';
import Tone from 'tone';
import { constants } from '../../constants';

import '../styles/top-menu.scss';
import Slider from 'react-rangeslider';
import { Subscribe } from 'unstated';
import PlusMinusInput from '../Musiscope/PlusMinusInput';
import MusiscopeControls from '../Musiscope/MusiscopeControls';
import ExportPane from '../Musiscope/ExportPane';

// import MicControls from '../Musiscope/MicControls';

// To include the default styles
import 'react-rangeslider/lib/index.css';
// To incude custom style
import '../styles/rangeslider-custom.scss';

import AudioRecorderContainer from '../../containers/AudioRecorderContainer';
import { MAX_NUM_WAVES} from '../WaveViewer/WaveViewer';
import WaveViewerContainer from '../../containers/WaveViewerContainer';
import MementoContainer from '../../containers/MementoContainer';
import { isMobile } from 'react-device-detect';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import MultiplyInput from '../Musiscope/MultiplyInput';

const options = [
  { key: 'Musiscope', text: '', value: 'Musiscope' },
  { key: 'Tutorial', text: 'Tutorial', value: 'Tutorial' },
  { key: 'Signal Generator', text: 'Signal Generator', value: 'Signal Generator' },
  { key: 'Spectrogram', text: 'Spectrogram', value: 'Spectrogram' },
  { key: 'Mflow', text: 'Mflow', value: 'Mflow' },
];

const EDIT = {
  SHORTER: 'shorter',
  LONGER: 'longer',
  LEFT: 'left',
  RIGHT: 'right'
};

/*const options2 = [
  { key: 'Length', text: 'Length', value: 'Length' },
  { key: 'Speed', text: 'Speed', value: 'Speed' },
  { key: 'Amplitude', text: 'Amplitude', value: 'Amplitude' },
  { key: 'Reverse', text: 'Reverse', value: 'Reverse' },
  { key: 'Reverb', text: 'Reverb', value: 'Reverb' }
];*/

const scaleForShorteningSelection = 9 / 10;
export const REC_DURATION_THRESHOLD_LEN = 300000; // 300 seconds
// const MAX_REPEAT_TIMES = 10

// Main Menu Class that renders children as individual tab panes
class TopMenu extends Component {
  constructor(props) {
    super(props);

    this.exportPaneRef = React.createRef();

    // exports openPopupWarn() function for ExportPane.js to open the warning modal
    this.openPopupWarn = this.openPopupWarn.bind(this)

    // Top Menu's state that we have is relevant to Top Menu's UI.
    this.state = {
      activeItem: null,
      effectError: false,
      effectErrorString: "",
      pane: null,
      selectionEdited: false, // whether the user has edited the selection since the last
      selectionLengthString: '',
      currentPlaying: {
        player: null,
        timerId: null
      },
      warningOpen: false,
      warningOpen2: false,
      isQuantizeSelectionPopUpOpen: false,
      reverb: false,
      isReverbSettingsPopUpOpen: false,
      decayTime: 500, // this value divided by 1000 is decay time in seconds
      isLengthPopUpOpen: false,
      isSpeedPopUpOpen: false,
      isAmplitudePopUpOpen: false,
      openPopUp: '',
      inputLength: 0, // millisecond
      inputSpeed: 0, // how many times faster
      inputAmplitude: 0,
      inputRepeatTime: 1,
      currentlyUpdatingRepeat: false,
    };
  }

  handleOpen = () => {
    this.setState({ isQuantizeSelectionPopUpOpen: true });
  };

  handleClose = () => {
    this.setState({ isQuantizeSelectionPopUpOpen: false });
  };

  // Function that switches between Menu Panes (children components)

  handleItemClick = (e, { name, audioRecorder }) => {
    let pane = null;

    switch (name) {
      case 'control-panel':
        if (name !== this.state.activeItem) {
          pane = <MusiscopeControls closeMenu={this.closeMenu} />;
        } else {
          name = null;
        }
        break;
      case 'export':
        if (name !== this.state.activeItem) {
          // pane = null;
          pane = (
            <ExportPane
              ref={this.exportPaneRef}
              closeMenu={this.closeMenu}
              isPlaying={audioRecorder.checkPlaying()}
            />
          );
          this.popupWarn(audioRecorder);
        } else {
          name = null;
        }
        break;
      default:
        pane = null;
    }
    this.setState({ activeItem: name, pane });
  };

  // Function that handles the close of the menu
  closeMenu = () => this.setState({ pane: null, activeItem: null });

  // Function that switches to the signal generator on click
  switchToOtherApp = (e, data) => {
    if (data.value === 'Tutorial') {
      window.location.href = 'https://listeningtowaves.com/tutorials';
    }
    if (data.value === 'Signal Generator') {
      window.location.href = 'https://signalgenerator.sciencemusic.org/';
    }
    if (data.value === 'Spectrogram') {
      window.location.href = 'https://spectrogram.sciencemusic.org/';
    }
    if (data.value === 'Mflow') {
      // this.props.history.push('/Spectrogram');
      window.location.href = 'https://mflow.sciencemusic.org/';
    }
    if (data.value === "Repeat") {
      this.setState({
        openPopUp: 'repeat'
      })
    }
  };

  popupWarn(audioRecorder) {
    if (audioRecorder.getRecordingDuration() >= REC_DURATION_THRESHOLD_LEN) {
      console.log('Warning: Recording duration past 5 minutes cannot be saved!');
      this.setState({ warningOpen: true });
    }
  }

  /** Handle ExportPane Prop passing * */

  openPopupWarn() {
    this.setState({ warningOpen: true });
  }

  closePopupWarn() {
    this.setState({ warningOpen: false });
  }

  openPopupWarn2() {
    this.setState({ warningOpen2: true });
  }

  closePopupWarn2() {
    this.setState({ warningOpen2: false });
  }

  onDownloadButtonClick = () => {
    this.props.onDownloadButtonClick();
  };

  // Future Email Button Implementation
  onEmailButtonClick = () => {
    this.props.onEmailButtonClick();
  };

  /** end */

  /**
   * @name onPlayBtnClick
   * @param audioRecorderContainer container holding AudioRecorder state and functionality
   * @param waveViewerContainer container holding waveViewerContainer state and functionality
   * @description Decides whether to play entire clip or play selection. If there's a selection,
   *              it will call function to play the selection; otherwise will call function to
   *              play entire clip.
   */
  onPlayBtnClick(audioRecorderContainer, waveViewerContainer) {
    const selection = waveViewerContainer.getSelection(); // use wv api
    // const selection = waveViewerContainer.getSelectionForRecorder(); // use wv api
    // if selection, play selection. Otherwise play clip
    console.log(waveViewerContainer.getSelection().start);
    console.log(waveViewerContainer.getSelection().end);
    console.log(waveViewerContainer.getPointSelectionFlag());
    if (waveViewerContainer.getPointSelectionFlag() && waveViewerContainer.getManualPtSelection()) {
      this.playFromPoint(selection.start, audioRecorderContainer, waveViewerContainer);
      //this.onPlaySelection(audioRecorderContainer, waveViewerContainer);
    } else if (audioRecorderContainer.getSelectedClipId() !== null &&
      waveViewerContainer.getSelection().end - waveViewerContainer.getSelection().start > 0) {
      //waveViewerContainer.setPointSelectionFlag(true);
      this.onPlaySelection(audioRecorderContainer, waveViewerContainer);
    } else {
      this.onPlayClip(audioRecorderContainer, waveViewerContainer);
    }
  }

  playFromPoint(point, arContainer, wvContainer) {
    arContainer.getPlayClips().then(playClips => {
      Object.values(playClips).forEach(audioBuffer => {
        this.onPlayHelper(audioBuffer, wvContainer, arContainer, point);
      });
    });
  }

  onPlayHelper(audioBuffer, waveViewerContainer, arContainer, point = null) {
    const acx = arContainer.getContext();
    let tonePlayer;
    if (this.state.reverb) {
      const rvb = new Tone.Reverb((this.state.decayTime / 1000) * 10 + 0.1);
      rvb.normalize = false;
      tonePlayer = new Tone.Player({ url: audioBuffer})
      rvb.generate().then(() => {
        tonePlayer.chain(rvb, acx.destination);
        // rvb.connect(this.reverbVolume);
      });

      // .connect(acx.destination);
    } else {
      tonePlayer = new Tone.Player(audioBuffer).connect(acx.destination);
    }
    // Volume updates in the new redesign
    tonePlayer.volume.value = arContainer.getOutputVolume();
    if (point) {
      const sampleRate = arContainer.getSampleRate();
      tonePlayer.start('+0', point / sampleRate);
    } else {
      tonePlayer.start();
    }

    // tp wrapper is just a pair of data, first one is the tonePlayer, second
    // one is a nochnage field meaning that we have set the start and end point
    // for looping at one point, and we then moved to a new selection, then we
    // stop the last audio, the first one loop should not then reflect the
    // selection on the screen( it should still play the original selection,
    // aka no change)
    const tpwrapper = {
      tp: tonePlayer,
      nochange: false
    };
    //TODO: should set isPlayer to false when the largest timer is done
    const stopTimerId = setTimeout(() => {
      // remove the player from the selection player after it is done playing
      arContainer.removeSelectionPlayer(tpwrapper.tp);
      arContainer.setIsPlaying(false);
    }, audioBuffer.duration * 1000);
    arContainer.appendSelectionPlayer(tpwrapper);
    arContainer.setIsPlaying(true);
    this.setState({
      //TODO: cache an array of current playing players and timerIDs
      currentPlaying: {
        // cache the current playing player and timer
        player: tonePlayer,
        timerId: stopTimerId
      }
    });
    let selection;
    if(point) {
      selection = waveViewerContainer.getSelection();
     
    } else {
      selection = {start: 0,end: arContainer.getLongestAudioDataLength()};
    }
    let end = arContainer.getLongestAudioDataLength();
    let duration = (end - selection.start)/arContainer.getSampleRate()
    const prevSelectionToSet = {
      start: selection.start,
      end: selection.end
    };
    waveViewerContainer.setPrevSelection(prevSelectionToSet);
    waveViewerContainer.setStartTime(Tone.context.now()*1000);
    waveViewerContainer.setDisplayBar(true);
    
    const playInterval = setInterval(() => {
        waveViewerContainer.setCurrentSelectionPlayTime(
          Tone.context.now()*1000 - waveViewerContainer.getStartTime()
        );
        let x = waveViewerContainer.getDisplayBarApproxIndex(arContainer.getSampleRate())
        if ( x + 100/waveViewerContainer.getScale() > waveViewerContainer.getEnd()) {
          waveViewerContainer.setEnd(x + 100/waveViewerContainer.getScale());
        }
        return;
      },30)
    arContainer.appendPlayInterval(playInterval);
    const stopfn = () => {
      console.log("called on stop fn")
      waveViewerContainer.setDisplayBar(false);
          waveViewerContainer.setCurrentSelectionPlayTime(0);
          clearInterval(playInterval);
          arContainer.removeSelectionPlayer(tpwrapper.tp);
          console.log("Stopped bar and interval")
      // tonePlayer.disconnect(); // Disconnecting here will create timing issues when playing accurate small chunk
    };
    setTimeout(stopfn, duration * 1000);
  }

  /**
   * @name onPlayClip
   * @param audioRecorderContainer container holding AudioRecorder state and functionality
   * @description Plays entire clip
   */
  onPlayClip(audioRecorderContainer, waveViewerContainer) {
    waveViewerContainer.skipToStart(audioRecorderContainer.getTotalSamplePoints())
    audioRecorderContainer.getPlayClips().then(playClips => {
      Object.values(playClips).forEach(audioBuffer => {
        this.onPlayHelper(audioBuffer, waveViewerContainer, audioRecorderContainer);
      });
    });
  }

  /**
 * @name onPlaySelection
 * @param {boolean} toLoop boolean to indicate whether the current selection to be played should be looped. Default is false
 * @param {integer?} stopLoopAt(milliseconds) If not null, stop the current player with/without loop after these many milliseconds.
 * @description Play selection, instantiate a new Tone.js player and append it to the array of players
 *
 */
  onPlaySelection(audioRecorderContainer, waveViewerContainer, toLoop = false, stopLoopAt = null) {
    let selection = waveViewerContainer.getSelection(); // use wv api
    if (!selection) {
      throw new Error('no selection');
    }

    const acx = audioRecorderContainer.getContext();
    const sampleRate = audioRecorderContainer.getSampleRate();
    waveViewerContainer.setNewSelection(false); // set to false so bar could move

    const selectedClipId = audioRecorderContainer.getSelectedClipId();
    audioRecorderContainer.getPlayClips().then(playClips => {
      
      const audioBuffer = playClips[selectedClipId];
      let tonePlayer;

      if (this.state.reverb) {
        const rvb = new Tone.Reverb((this.state.decayTime / 1000) * 10 + 0.1);
        rvb.normalize = false;
        tonePlayer = new Tone.Player({
          url: audioBuffer,
          loop: toLoop
        });
        rvb.generate().then(() => {
          tonePlayer.chain(rvb, acx.destination);
          // rvb.connect(this.reverbVolume);
        });

        // .connect(acx.destination);
      } else {
        tonePlayer = new Tone.Player({
          url: audioBuffer,
          loop: toLoop
        }).connect(acx.destination);
      }
      // Volume controls to be done
      tonePlayer.volume.value = audioRecorderContainer.getOutputVolume(); // adjust volume

      const tpwrapper = {
        tp: tonePlayer,
        nochange: false
      };
      // append the player to the array of running player
      audioRecorderContainer.appendSelectionPlayer(tpwrapper);
      audioRecorderContainer.setIsPlaying(true);
      // If stopLoopAt exists, stop the current player after the given milliseconds
      if (typeof stopLoopAt === 'number') {
        setTimeout(() => tonePlayer.stop(), stopLoopAt);
      }

      selection = waveViewerContainer.getSelection();
      let selectedDuration = (selection.end - selection.start) / sampleRate;
      let selectedOffset = selection.start / sampleRate;

      if (toLoop) {
        tonePlayer.setLoopPoints(selectedOffset, selectedOffset + selectedDuration);
        tonePlayer.start();
      } else {
        tonePlayer.start('+0', selectedOffset, selectedDuration);
      }
      const prevSelectionToSet = {
        start: selection.start,
        end: selection.end
      };
      waveViewerContainer.setPrevSelection(prevSelectionToSet);

      // let st = Tone.context.now();
      waveViewerContainer.setStartTime(Tone.context.now()*1000);
      
      waveViewerContainer.setDisplayBar(true);
      console.log("selection in play", selection)
      const playInterval = setInterval(() => {
        const sp = audioRecorderContainer.getSelectionPlayer();
        // console.log("Current tone time:" ,Tone.context.now() - st);
        // console.log("Diff from start time:" ,new Date().getTime() -  waveViewerContainer.getStartTime());
        // just update the bar if we are not looping
        if (!toLoop) {
          waveViewerContainer.setCurrentSelectionPlayTime(
            Tone.context.now()*1000 - waveViewerContainer.getStartTime()
          );
          return;
        }
        
        // check if the current player should be edited
        // needs to be the newest one in the sp array and we are not adding a
        // new selection
        if (
          sp[sp.length - 1] === tpwrapper &&
          !waveViewerContainer.getNewSelection() &&
          !tpwrapper.nochange
        ) {
          selection = waveViewerContainer.getSelection();
          const prevSelection = waveViewerContainer.getPrevSelection();

          selectedDuration = (selection.end - selection.start) / sampleRate;
          selectedOffset = selection.start / sampleRate;

          // based on if the previous selection is a change of offset or tempo we wtih set the endcheck to either the end of the current end or the previous end
          const endCheck =
            (waveViewerContainer.getKeepPlayingToOldEnd() ? prevSelection.end : selection.end) /
            sampleRate;
          // based on if the previous selection is a change of offset or tempo we wtih set the startcheck to either the start of the current end or the previous start
          const startCheck =
            (waveViewerContainer.getKeepPlayingToOldEnd() ? prevSelection.start : selection.start) /
            sampleRate;

          if (!waveViewerContainer.getIsTranslating()) {
            // we are lengthing/shortening, or user has not editted the selection
            // hitting loop twice will results in synchronized tracks if
            // we are in this branch
            tonePlayer.setLoopPoints(selectedOffset, selectedOffset + selectedDuration);
            waveViewerContainer.setPrevSelection({
              start: selection.start,
              end: selection.end
            });
          } else if (
            waveViewerContainer.getCurrentSelectionPlayTime() +
            (prevSelection.start / sampleRate) * 1000 >
            endCheck * 1000 ||
            waveViewerContainer.getCurrentSelectionPlayTime() +
            (prevSelection.start / sampleRate) * 1000 <
            startCheck * 1000
          ) {
            waveViewerContainer.setIsTranslating(false);

            tonePlayer.stop();
            tonePlayer.setLoopPoints(selectedOffset, selectedOffset + selectedDuration);
            tonePlayer.start();
            waveViewerContainer.setPrevSelection({
              start: selection.start,
              end: selection.end
            });
          }

          // reset bar to the beginning if next cycle will go out of range
          if (
            waveViewerContainer.getCurrentSelectionPlayTime() +
            (prevSelection.start / sampleRate) * 1000 + 30 >
            endCheck * 1000
          ) {
            // TODO: timing not accurate
            // let newStartTime = waveViewerContainer.getStartTime() + ((selection.end - selection.start) / sampleRate) * 1000;
            // waveViewerContainer.setStartTime(newStartTime);
            // waveViewerContainer.setCurrentSelectionPlayTime(
            //   new Date().getTime() - newStartTime
            // );
            let newStartTime = Tone.context.now()*1000;
            waveViewerContainer.setStartTime(newStartTime);
            waveViewerContainer.setCurrentSelectionPlayTime(Tone.context.now()*1000 - newStartTime);
          }

          waveViewerContainer.setCurrentSelectionPlayTime(
            Tone.context.now()*1000 - waveViewerContainer.getStartTime()
          );

          // display bar for shorter selections
          if (selectedDuration <= 0.1) {
            waveViewerContainer.setDisplayBar(false);
          } else {
            waveViewerContainer.setDisplayBar(true);
          }
        } else {
          tpwrapper.nochange = true;
        }
      }, 30);

      audioRecorderContainer.appendPlayInterval(playInterval);

      // once the tonePlayer finishes, remove it from the players array
      if (!toLoop) {
        setTimeout(() => {
          waveViewerContainer.setDisplayBar(false);
          audioRecorderContainer.setIsPlaying(false);
          clearInterval(playInterval);
          waveViewerContainer.setCurrentSelectionPlayTime(0);
          audioRecorderContainer.removeSelectionPlayer(tpwrapper.tp);
          // tonePlayer.disconnect(); // Disconnecting here will create timing issues when playing accurate small chunk
        }, selectedDuration * 1000);
      }
    });
  }

  onStopLastAudio(audioRecorderContainer) {
    // stop the currently playing full clip
    const { currentPlaying } = this.state;
    const lastPlayer = audioRecorderContainer.getLastPlayer();

    if (currentPlaying.player === lastPlayer.tp) {
      if (currentPlaying.timerId) {
        // clear the timer
        clearTimeout(currentPlaying.timerId);
      }

      if (currentPlaying.player) {
        // stop the player first
        currentPlaying.player.stop();

        // clear the player and stop from playing
        audioRecorderContainer.removeSelectionPlayer(currentPlaying.player);
      }

      // reset the currentPlaying to null and isPlaying to false
      audioRecorderContainer.setIsPlaying(false);
      this.setState({
        currentPlaying: {
          player: null,
          timerId: null
        },
      });
      
    } else {
      audioRecorderContainer.stopLastPlayer();
    }
  }

  onStopAllSelection(wvContainter, audioRecorderContainer) {
    audioRecorderContainer.stopAllSelectionPlayers(); // stop all the running players
    wvContainter.setCurrentSelectionPlayTime(0);
    audioRecorderContainer.setIsPlaying(false);
    wvContainter.setDisplayBar(false);
  }

  isEffectsDisabled(audioRecorder, workWhilePlay = false) {
    return (audioRecorder.getWaveMapSize() <= 0 ||
      audioRecorder.getSelectedClipId() === null ||
      audioRecorder.isRecording() ||
      (!workWhilePlay && audioRecorder.getLastPlayer() !== undefined) || // some clip is playing/looping
      (!workWhilePlay && audioRecorder.checkPlaying()))
  }

  /**
   * Handles the behavior of the copy button.
   */
  onCopyBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log('onCopyBtnClick ', selection);
    audioRecorder.copySelection(selection.start, selection.end);
  }

  /**
   * Handles the behavior of the insert paste button.
   * If some snippet is selected, replace it with the copied snippet. If there's a point selection,
   * insert the copied snippet at the point. In this case selection.start and .end only differs by 1 so we 
   * replace this snippet directly. 
   */
  onInsertPasteBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    if (audioRecorder.getWaveMapSize() >= MAX_NUM_WAVES && audioRecorder.getSelectedClipId() === null) {
      console.log(`Warning: can't create more than ${MAX_NUM_WAVES} tracks!`);
      this.setState({ warningOpen2: true })
    } else {
      // toggle the recording
      audioRecorder.pasteToSelection(selection.start, selection.end, wvContainer);
    }
  }

  /**
   * @deprecated for multi-wave version
   * Handles the behavior of the overlap paste button.
   * If some snippet is selected, overlap the length with the copied snippet. If there's a point selection,
   * overlap the copied snippet after the point.
   */
  onOverlapPasteBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log(selection);
    if (wvContainer.getPointSelectionFlag() && wvContainer.getManualPtSelection()) {
      // if point selection, overlap after start of selection
      audioRecorder.overlapOnSelection(selection.start, null, wvContainer);
    } else {
      // if not point selection, overlap on top of the selection
      audioRecorder.overlapOnSelection(selection.start, selection.end, wvContainer);
    }
  }

  /**
   * Handles the behavior of the undo button.
   * If able to undo, roll back to previous snapshot.
   */
  onUndoBtnClick(audioRecorder, wvContainer, mContainer) {
    if (mContainer.canUndo()) {
      mContainer.undo(audioRecorder, wvContainer);
    }
  }

  /**
   * Handles the behavior of the redo button.
   * If able to redo, roll back to next snapshot.
   */
  onRedoBtnClick(audioRecorder, wvContainer, mContainer) {
    if (mContainer.canRedo()) {
      mContainer.redo(audioRecorder, wvContainer);
    }
  }

  /**
   * Handles the behavior of the Repeat button.
   * Copies a segment of recording multiple times immediately after the selection
   */
  async onRepeatBtnClick(audioRecorder, wvContainer) {
    let selection = wvContainer.getSelection();
    console.log(selection);
    if (this.state.inputRepeatTime > 0) {
      await audioRecorder.repeatSelection(
        selection.start,
        selection.end,
        wvContainer,
        this.state.inputRepeatTime
      );
    }
    this.resetRepeatTime();
  }

  async onToggleRecordBtn(audioRecorder, wvContainer, playFromPoint = true) {
    const start = wvContainer.getSelection().start;
    wvContainer.clearSelection();
    wvContainer.setNewSelection(true);
    wvContainer.setDisplayBar(false);
    wvContainer.setCurrentSelectionPlayTime(0);

    // If still recording, reset the export button and stop the playing; else, start playing current recording
    if (audioRecorder.isRecording()) {
      if (this.exportPaneRef && this.exportPaneRef.current) {
        this.exportPaneRef.current.reset(); // reset the save button
      }
      this.onStopAllSelection(wvContainer, audioRecorder);
    } else {
      if (audioRecorder.getWaveMapSize() >= Math.floor(MAX_NUM_WAVES) && audioRecorder.getSelectedClipId() === null) {
        // When attempting to make 6th track, disallow that
        console.log(`Warning: can't create more than ${MAX_NUM_WAVES} tracks!`);
        // this.setState({ warningOpen2: true });
        // return;
      } else if (audioRecorder.getSelectedClipId() !== null && playFromPoint) {
        // When starting recording on an existed track, play from the endpoint of that track
        let { audioData } = audioRecorder.getSelectedClipAndData();
        await this.playFromPoint(audioData.length, audioRecorder, wvContainer);
      } else if (wvContainer.getPointSelectionFlag() && wvContainer.getManualPtSelection() && playFromPoint) {
        // When starting recording on a new track with a start point, play from that point
        await this.playFromPoint(start, audioRecorder, wvContainer);
      } else if (playFromPoint) {
        // Play from beginning
        await this.onPlayClip(audioRecorder);
      }
    }
    let time = Date.now();

    // toggle the recording
    await audioRecorder.toggleRecording(start);
    console.log(Date.now() - time)
  }

  onToolClicked(audioRecorder, wvContainer) {
    audioRecorder.toggleTool();
  }

  /**
   * @name onClearBtnClick()
   * @description Handles behavior of clear button. If there's a selected portion,
   *  delete that portion only; else, delete the whole clip.
   * @param {*} audioRecorder container holding AudioRecorder state and functionality
   * @param {*} wvContainer container holding WaveViewer state and functionality
   * @param {*} mContainer container holding memento(snapshot) state and functionality
   */
  onClearBtnClick(audioRecorder, wvContainer) {
    const selection = wvContainer.getSelection();

    if (
      selection != null &&
      wvContainer.getPointSelectionFlag() === false &&
      selection.start !== selection.end
    ) {
      audioRecorder.deleteSelection(selection.start, selection.end, wvContainer);
      wvContainer.setCurrentSelectionPlayTime(0);
    } else {
      // remove everything if there's no selection or point selection
      // audioRecorder.deleteStoredAudioData();
      audioRecorder.deleteClip();

      // If everything got deleted, reset the wave viewer state
      if(audioRecorder.getWaveMapSize() === 0) {
        wvContainer.setScale();
        wvContainer.setEnd(0);
      }
      wvContainer.setCurrentSelectionPlayTime(0);
      // reset point selection flag
      wvContainer.setPointSelectionFlag(false);
      wvContainer.setManualPtSelection(true);

      // clear the selection
      wvContainer.setNewSelection(true);
      wvContainer.clearSelection();

      // reset bar
      wvContainer.setDisplayBar(false);

      // reset styles for save warning
      // document.getElementById("save-warning").disabled = true;
      // document.getElementById("save-warning").classList.add("disabled");
      // document.getElementById('export-tab-name').style.color = '';
    }
  }

  onClearButSelectionBtnClick(audioRecorder, wvContainer) {
    const selection = wvContainer.getSelection();
    if (
      selection != null &&
      wvContainer.getPointSelectionFlag() === false &&
      selection.start !== selection.end
    ) {
      audioRecorder.deleteButSelection(selection.start, selection.end, wvContainer);
      wvContainer.setCurrentSelectionPlayTime(0);
    }
  }

  newSelectionOnClick(wvContainer) {
    wvContainer.setNewSelection(true);
  }

  editSelection(audioRecorder, wvContainer, editType) {
    this.originselection = {
      start: wvContainer.getSelection().start,
      end: wvContainer.getSelection().end
    };
    const updateSelection = {
      start: this.originselection.start,
      end: this.originselection.end
    };
    const samplesOnScreen = wvContainer.getCanvasReference().width / wvContainer.getScale();
    const samplesInSelection = this.originselection.end - this.originselection.start;
    let keepPlayingToOldEnd = false;
    switch (editType) {
      case EDIT.SHORTER:
        updateSelection.end =
          updateSelection.start + samplesInSelection * scaleForShorteningSelection;
        break;
      case EDIT.LONGER:
        updateSelection.end =
          updateSelection.start + samplesInSelection / scaleForShorteningSelection;
        break;
      case EDIT.LEFT:
        // not allowing to go beyond the left edge of the screen
        if (updateSelection.start - samplesOnScreen / 50 > 0) {
          updateSelection.start -= samplesOnScreen / 50;
          updateSelection.end -= samplesOnScreen / 50;
        }
        keepPlayingToOldEnd = true;
        break;

      case EDIT.RIGHT:
        // not allowing to go beyond the end of the samples
        if (updateSelection.end + samplesOnScreen / 50 < wvContainer.getEnd()) {
          updateSelection.start += samplesOnScreen / 50;
          updateSelection.end += samplesOnScreen / 50;
        }
        keepPlayingToOldEnd = true;
        break;

      default:
        break;
    }
    wvContainer.setKeepPlayingToOldEnd(keepPlayingToOldEnd);
    this.setState({
      selectionEdited: true
    });
    // // Trigger Musiscope to set selection of buffers upon what the user has entered
    // setState is async call so need to use updateSelection rather than this.state.selection
    wvContainer.setSelection(updateSelection);
    wvContainer.setSelectionLengthString(
      wvContainer.getSelectionLength(audioRecorder.getSampleRate())
    );
  }

  quantizeSelection(audioRecorder, wvContainer, length) {
    this.originselection = {
      start: wvContainer.getSelection().start,
      end: wvContainer.getSelection().end
    };
    const updateSelection = {
      start: this.originselection.start,
      end: this.originselection.end
    };

    const keepPlayingToOldEnd = false;
    updateSelection.end = updateSelection.start + length * audioRecorder.getSampleRate();
    wvContainer.setKeepPlayingToOldEnd(keepPlayingToOldEnd);
    // // Trigger Musiscope to set selection of buffers upon what the user has entered
    // setState is async call so need to use updateSelection rather than this.state.selection
    this.setState({
      selectionEdited: true
    });
    wvContainer.setSelection(updateSelection, audioRecorder.getSampleRate());
    // wvContainer.setSelectionLengthString(wvContainer.getSelectionLength(audioRecorder.getSampleRate()));
  }

  toggleQuantizeSelection() {
    this.setState(state => ({
      isQuantizeSelectionPopUpOpen: !state.isQuantizeSelectionPopUpOpen
    }));
  }

  reverse(arContainer, wvContainer) {
    const selection = wvContainer.getSelection();
    if (selection != null && selection.start !== selection.end) {
      arContainer.reverse(selection.start, selection.end);
    } else {
      // revert entire track if there's no selection
      arContainer.reverse();
    }
  }

  resample(arContainer, wvContainer, value) {
    if (value < 100) {
      console.log('resample to a speed too slow');
      return;
    }
    arContainer.setResampleRatio(value);
    const selection = wvContainer.getSelection();
    let tempEnd = wvContainer.getEnd();
    if (selection != null && Math.abs(selection.start - selection.end) >= 1.1) {
      arContainer.resample(value / 1000, wvContainer, selection.start, selection.end, tempEnd);
    } else {
      // revert entire track if there's no selection
      arContainer.resample(value / 1000, wvContainer, 0, tempEnd, tempEnd);
    }
  }

  changeAmplitude(arContainer, wvContainer, value, rawRatio = false) {
    let amplitudeRatio;
    if (rawRatio) {
      amplitudeRatio = value;
      arContainer.setAmplitudeRatio(
        (Math.log(value) / Math.log(constants.AMPLITUDE_LOG_RATIO) + 2) * 1000
      );
    } else {
      amplitudeRatio = constants.AMPLITUDE_LOG_RATIO ** (value / 1000 - 2);
      arContainer.setAmplitudeRatio(value);
    }
    const selection = wvContainer.getSelection();
    if (selection != null && Math.abs(selection.start - selection.end) >= 1.1) {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer, selection.start, selection.end);
    } else {
      arContainer.changeAmplitude(amplitudeRatio, wvContainer);
    }
  }

  handleReverbClick = () => {
    this.setState(prevState => ({ reverb: !prevState.reverb }));
  };

  handleLengthChange = (e, arContainer) => {
    if (isNaN(e.target.value)) return;
    this.setState({ inputLength: e.target.value });
    arContainer.setResetLengthDisplay(false);
  };

  handleSpeedChange = (e, arContainer) => {
    if (isNaN(e.target.value)) return;
    this.setState({ inputSpeed: e.target.value });
    arContainer.setResetResampleDisplay(false);
  };

  handleAmplitudeChange = (e, arContainer) => {
    if (isNaN(e.target.value)) return;
    this.setState({ inputAmplitude: e.target.value });
    arContainer.setResetAmplitudeDisplay(false);
  };

  handleRepeatTimeChange = e => {
    if (isNaN(e.target.value)) return;
    this.setState({ inputRepeatTime: e.target.value });
  };

  showErrorDialog = (errorStr) => {
    if (!this.state.effectError) {
      this.setState({ effectError: true, effectErrorString: errorStr })
    }
  }

  resetRepeatTime() {
    this.setState({ inputRepeatTime: 1 });
  }

  // handleLengthChangeSubmit = wvContainer => {
  //   wvContainer.setLen;
  // };

  /**
   * Applies reverb to selection or entire buffer in absence of selection
   */
  async applyReverb(audioRecorder, wvContainer) {
    // find selection indices
    const selection = wvContainer.getSelection();

    if (audioRecorder.getAudioDataLength() !== 0) {
      let selectionLength;
      if (selection.start !== selection.end) {
        selectionLength = (await audioRecorder.getBuffer(selection.start, selection.end)).length;
      } else {
        // 0 0 for entire track
        selectionLength = (await audioRecorder.getBuffer(0, 0)).length;
      }
      // construct offline context with reverb

      if (selectionLength !== 0) {
        const offlineCtx = new OfflineAudioContext(
          1,
          selectionLength,
          audioRecorder.getSampleRate()
        );

        // load impulse response from file
        const reverb = offlineCtx.createConvolver();
        const reverbGain = offlineCtx.createGain();
        reverbGain.value = 1.3;
        const response = await fetch(
          'https://soundbasketlisten.s3-us-west-1.amazonaws.com/rir_jack_lyons_lp2_96k.wav'
        );
        const arraybuffer = await response.arrayBuffer();
        reverb.buffer = await offlineCtx.decodeAudioData(arraybuffer);

        // connect nodes
        reverb.connect(reverbGain);
        reverbGain.connect(offlineCtx.destination);

        // apply reverb
        audioRecorder.applyEffect(offlineCtx, reverb, selection);
      }
    }
  }

  // Renders the top Menu Bar with tabs, microphone gain, and the control panel buttons
  // as well as the graph scale and which tab to render
  render() {
    // const { activeItem } = this.state;

    return (
      <Subscribe to={[AudioRecorderContainer, WaveViewerContainer, MementoContainer]}>
        {(audioRecorder, wvContainer, mContainer) => (
          <div className="menu-container">
            <Menu color="#ABE2FB" className="menu-menu" attached="bottom">
              <Menu.Item className="app-bar-dropdown-container">
                <Dropdown
                  text="Musiscope"
                  className="top-app-bar-dropdown"
                  selection
                  options={options}
                  onChange={this.switchToOtherApp}
                />
              </Menu.Item>
              {/* NOTE: Commented out Control Panel. Need to display it in the future */}
              {/* <Menu.Item name='control-panel' active={activeItem === 'control-panel'} onClick={this.handleItemClick} className="tab-item" style={soundStyle} /> */}
              {/* <Menu.Item name='advanced' active={activeItem === 'advanced'} onClick={this.handleItemClick} className="tab-item"/> */}

              {/* REVAMPED BUTTONS */}
              <Menu.Item className="importantButtons horiz">
                <Button.Group className="horiz">
                  <Popup
                    trigger={
                      // enclose button in span to allow popup in disabled state
                      <span>
                        <Button
                          icon
                          size="tiny"
                          /*className="loop-btn btn-left"*/

                          id="loop-button"
                          onClick={() => this.onPlaySelection(audioRecorder, wvContainer, true)}
                          disabled={
                            (!(wvContainer.getSelection().end - wvContainer.getSelection().start) ||
                              wvContainer.getPointSelectionFlag() ||
                              audioRecorder.getSelectedClipId() === null) &&
                            wvContainer.getManualPtSelection()
                          }
                        >
                          <Icon fitted name="sync alternate" />
                        </Button>
                      </span>
                    }
                    disabled={!(wvContainer.getSelection().end - wvContainer.getSelection().start)}
                    className="fitted-padding"
                    content={
                      wvContainer.getPointSelectionFlag() && wvContainer.getManualPtSelection()
                        ? 'Loop from Line to End'
                        : 'Loop Highlighted Section'
                    }
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          className="stop-btn btn-middle"
                          size="tiny"
                          id="stop-button"
                          onClick={() => this.onStopAllSelection(wvContainer, audioRecorder)}
                          disabled={audioRecorder.getLastPlayer() === undefined}
                        >
                          <Icon fitted name="stop" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Stop All"
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          className="play-pause btn-middle"
                          size="tiny"
                          onClick={() => this.onPlayBtnClick(audioRecorder, wvContainer)}
                          disabled={
                            audioRecorder.isRecording() || audioRecorder.getWaveMapSize() <= 0
                          }
                        >
                          <Icon fitted name="play" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content={
                      !(wvContainer.getSelection().end - wvContainer.getSelection().start)
                        ? 'Play Entire Clip'
                        : 'Play Selection'
                    }
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                  
                  {/* <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="tiny"
                          className="record btn-middle AudioRecorder-button-record"
                          onClick={async () => {
                            await this.onToggleRecordBtn(audioRecorder, wvContainer);
                            if (!audioRecorder.isRecording()) {
                              mContainer.takeSnapshot(audioRecorder, wvContainer);
                            }
                          }}
                          disabled={
                            (audioRecorder.getWaveMapSize() < 1 && audioRecorder.getSelectedClipId() === null)
                            || (audioRecorder.getWaveMapSize() === 1 && audioRecorder.getSelectedClipId() !== null)
                          }
                        >
                          {audioRecorder.isRecording() ? (
                            <Icon fitted name="stop circle" size="large" color="red" />
                          ) : (
                            <Icon fitted name="play circle" color="red" />
                          )}
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Record and Play"
                    position="bottom center"
                    inverted
                    size="tiny"
                  /> */}
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="tiny"
                          className="record btn-middle AudioRecorder-button-record"
                          onClick={async () => {
                            await this.onToggleRecordBtn(audioRecorder, wvContainer, false);
                            if (!audioRecorder.isRecording()) {
                              await mContainer.takeSnapshot(audioRecorder, wvContainer);
                            }
                          }}
                        >
                          {audioRecorder.isRecording() ? (
                            <Icon fitted name="stop circle" size="large" color="red" />
                          ) : (
                            <Icon fitted name="circle" color="red" />
                          )}
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content="Record"
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="tiny"
                          className="clear btn-right"
                          onClick={async () => {
                            await mContainer.takeSnapshot(audioRecorder, wvContainer);
                            await this.onClearBtnClick(audioRecorder, wvContainer);
                            
                          }}
                          disabled={
                            audioRecorder.isRecording() ||
                            audioRecorder.getWaveMapSize() <= 0 ||
                            audioRecorder.getLastPlayer() !== undefined ||
                            audioRecorder.checkPlaying()
                          }
                        >
                          <Icon fitted name="trash alternate outline" color="red" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content={
                      !(wvContainer.getSelection().end - wvContainer.getSelection().start)
                        ? 'Clear'
                        : 'Delete Selection'
                    }
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                  <Popup
                    trigger={
                      <span>
                        <Button
                          icon
                          size="tiny"
                          className="clear btn-right"
                          onClick={async () => {
                            await mContainer.takeSnapshot(audioRecorder, wvContainer);
                            await this.onClearButSelectionBtnClick(audioRecorder, wvContainer);
                            // mContainer.takeSnapshot(audioRecorder, wvContainer);
                          }}
                          disabled={
                            this.isEffectsDisabled(audioRecorder, false) || !(wvContainer.getSelection().end - wvContainer.getSelection().start)
                          }
                        >
                          <Icon fitted name="trash" color="purple" />
                        </Button>
                      </span>
                    }
                    className="fitted-padding"
                    content={
                      !(wvContainer.getSelection().end - wvContainer.getSelection().start)
                        ? 'Clear'
                        : 'Delete Everything But Selection'
                    }
                    position="bottom center"
                    inverted
                    size="tiny"
                  />
                </Button.Group>
              </Menu.Item>


              {/* Microphone Gain. Moved to Bottom-Menu */}
              {/* <Menu.Item className="microphone-positioning">
                <MicControls />
              </Menu.Item> */}

              {/* Output Volume */}
              {/* <Menu.Item>
                <Popup
                  trigger={<Icon name="volume up" />}
                  className="fitted-padding"
                  content="Volume"
                  position="top center"
                  inverted
                  size="small"
                />
                <Slider
                  min={-15}
                  max={15}
                  value={audioRecorder.getOutputVolume()}
                  onChange={value => audioRecorder.setOutputVolume(value)}
                  tooltip={false}
                  className="slider"
                />
              </Menu.Item> */}

              {/* Length Control */}
              {false && ( //!isMobile &&
                <Menu.Item>
                  <Button
                    //size = 'big'
                    className="top-menu-button"
                    onClick={() => {
                      if (this.state.openPopUp === 'length') {
                        this.setState({
                          openPopUp: ''
                        });
                      } else {
                        this.setState({
                          openPopUp: 'length'
                        });
                      }
                      // this.setState(state => ({ isLengthPopUpOpen: !state.isLengthPopUpOpen }));
                    }}
                  >
                    Length
                  </Button>
                </Menu.Item>
              )}

              {this.state.openPopUp === 'length' && !isMobile && (
                <div>
                  <div
                    className="popup-mask"
                    onClick={() => {
                      this.setState({
                        openPopUp: ''
                      });
                    }}
                  ></div>
                  <div
                    style={{
                      top: '90px',
                      position: 'absolute',
                      background: 'white',
                      zIndex: 2,
                      left: '390px',
                      borderRadius: '.28571429rem',
                      padding: '10px'
                    }}
                  >
                    <div>
                      <span> &nbsp;Selection Length: </span>
                      <Slider
                        min={0}
                        max={70}
                        value={
                          Math.log(wvContainer.getSelectionLength(audioRecorder.getSampleRate())) /
                          Math.log(1 / scaleForShorteningSelection)
                        }
                        onChange={value => {
                          wvContainer.updateSelectionLength(
                            audioRecorder,
                            value,
                            scaleForShorteningSelection,
                            audioRecorder.getSampleRate()
                          );

                          this.setState({
                            inputLength: (
                              ((1 / scaleForShorteningSelection) ** value / 1000) *
                              1000
                            ).toFixed(2) // display 2 decimal points
                          });
                        }}
                        tooltip={false}
                        className="slider"
                      />

                      {/* <span>{wvContainer.getSelectionLengthString()}&nbsp;&nbsp;</span> */}
                    </div>

                    <label>
                      <input
                        type="number"
                        value={
                          audioRecorder.getResetLengthDisplay()
                            ? wvContainer
                              .getSelectionLength(audioRecorder.getSampleRate())
                              .toFixed(2)
                            : //   Math.log(
                            //     wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                            //   ) / Math.log(1 / scaleForShorteningSelection)
                            // ).toFixed(2)
                            this.state.inputLength
                        }
                        onChange={e => this.handleLengthChange(e, audioRecorder)}
                      ></input>{' '}
                      ms
                    </label>
                    <button
                      onClick={() => {
                        const value =
                          Math.log(this.state.inputLength) /
                          Math.log(1 / scaleForShorteningSelection);
                        wvContainer.updateSelectionLength(
                          audioRecorder,
                          value,
                          scaleForShorteningSelection,
                          audioRecorder.getSampleRate()
                        );
                        wvContainer.setManualPtSelection(false);
                        //console.log("setPointSelectionFlag(false)");
                      }}
                    >
                      enter
                    </button>
                  </div>
                </div>
              )}

              {this.state.openPopUp === 'amplitude' && ( //!isMobile &&
                <div>
                  <div
                    className="popup-mask"
                    onClick={() => {
                      this.setState({
                        openPopUp: ''
                      });
                    }}
                  ></div>
                  <div
                    style={{
                      top: '90px',
                      position: 'absolute',
                      background: 'white',
                      zIndex: 2,
                      left: '610px',
                      borderRadius: '.28571429rem',
                      padding: '10px'
                    }}
                  >
                    <div>
                      amplitude:
                      <Slider
                        min={20}
                        max={4000}
                        value={audioRecorder.getAmplitudeRatio()}
                        onChange={value => {
                          this.changeAmplitude(audioRecorder, wvContainer, value);
                          this.setState({
                            // inputAmplitude: audioRecorder.getAmplitudeRatio() /
                            // 1000
                            inputAmplitude:
                              constants.AMPLITUDE_LOG_RATIO **
                              (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                          });
                        }}
                        onChangeComplete={() => mContainer.takeSnapshot(audioRecorder, wvContainer)}
                        tooltip={false}
                        className="slider"
                      />
                      {/* {audioRecorder.getAmplitudeRatio() / 1000} */}
                    </div>
                    <label>
                      <input
                        type="number"
                        value={
                          audioRecorder.getResetAmplitudeDisplay()
                            ? (
                              constants.AMPLITUDE_LOG_RATIO **
                              (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                            ).toFixed(2)
                            : this.state.inputAmplitude
                        }
                        onChange={e => this.handleAmplitudeChange(e, audioRecorder)}
                      ></input>{' '}
                      x
                    </label>
                    <button
                      onClick={async () => {
                        await mContainer.takeSnapshot(audioRecorder, wvContainer);
                        await this.changeAmplitude(
                          audioRecorder,
                          wvContainer,
                          this.state.inputAmplitude,
                          true
                        );
                        
                      }}
                    >
                      enter
                    </button>{' '}
                  </div>
                </div>
              )}

              {/* <MenuItem>
                <Button onClick={() => this.toggleQuantizeSelection()}>Edit Selection</Button>
              </MenuItem> */}
              <div
                style={{
                  position: 'absolute',
                  display: this.state.isQuantizeSelectionPopUpOpen ? 'block' : 'none',
                  top: '90px',
                  left: '750px',
                  zIndex: '1',
                  padding: '10px'
                }}
              >
                <div style={{ backgroundColor: 'white', borderRadius: '.28571429rem' }}>
                  {/* <button onClick={() => this.newSelectionOnClick(wvContainer)}>
                    {' '}
                    new selection{' '}
                  </button> */}
                  <div>
                    <button
                      className="selectionButtons"
                      onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.LEFT)}
                    >
                      {' '}
                      &lt;-{' '}
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.RIGHT)}
                    >
                      {' '}
                      -&gt;{' '}
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.LONGER)}
                    >
                      longer
                    </button>
                    <button
                      className="selectionButtons"
                      onClick={() => this.editSelection(audioRecorder, wvContainer, EDIT.SHORTER)}
                    >
                      shorter
                    </button>
                  </div>
                </div>
              </div>

              {this.state.openPopUp === 'Reverse' && (
                this.reverse(audioRecorder, wvContainer),
                this.setState({ openPopUp: "" })
              )}
              {this.state.openPopUp === 'Reverb' && (
                this.applyReverb(audioRecorder, wvContainer),
                this.setState({ openPopUp: "" })
              )}
               {!audioRecorder.isRecording() && audioRecorder.getWaveMapSize() > 0 && (
                <Menu.Item className="importantButtons horiz">
               <Popup
                      trigger={
                        // enclose button in span to allow popup in disabled state
                        <span>
                          <Button
                            icon
                            size="tiny"
                            className="undo-btn btn-middle"
                            onClick={() =>
                              this.onUndoBtnClick(audioRecorder, wvContainer, mContainer)
                            }
                            disabled={
                              audioRecorder.isRecording() ||
                              audioRecorder.getLastPlayer() !== undefined ||
                              !mContainer.canUndo() ||
                              audioRecorder.checkPlaying()
                            }
                          >
                            <Icon fitted name="undo alternate" />
                          </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Undo"
                      position="bottom center"
                      inverted
                      size="tiny"
                    />
                    <Popup
                      trigger={
                        // enclose button in span to allow popup in disabled state
                        <span>
                          <Button
                            icon
                            size="tiny"
                            className="redo-btn btn-right"
                            onClick={() =>
                              this.onRedoBtnClick(audioRecorder, wvContainer, mContainer)
                            }
                            disabled={
                              audioRecorder.isRecording() ||
                              audioRecorder.getLastPlayer() !== undefined ||
                              !mContainer.canRedo() ||
                              audioRecorder.checkPlaying()
                            }
                          >
                            <Icon fitted name="redo alternate" />
                          </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Redo"
                      position="bottom center"
                      inverted
                      size="tiny"
                    />
              </Menu.Item>)}

              {!audioRecorder.isRecording() && audioRecorder.getWaveMapSize() > 0 && (
                <>
                <Menu.Item className="importantButtons horiz">
                  <Button.Group className="CopyPasteBtns">
                    <Popup
                      trigger={
                        <span>
                          <Button
                            className="btn-left"
                            size="tiny"
                            onClick={async () => this.onCopyBtnClick(audioRecorder, wvContainer)}
                            disabled={
                              audioRecorder.isRecording() || // is recording
                              audioRecorder.getAudioDataLength() <= 0 || // no recording
                              wvContainer.getSelection() == null || // no selection
                              wvContainer.getPointSelectionFlag() || // point selection
                              wvContainer.getSelection().end - wvContainer.getSelection().start <= 0 || // selection is not positive length
                              audioRecorder.getLastPlayer() !== undefined || // some clip is playing/looping
                              audioRecorder.getSelectedClipId() === null || // not selecting any clip
                              audioRecorder.checkPlaying() // is playing the clip
                            }
                          >
                            Copy
                          </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Copy"
                      position="bottom center"
                      inverted
                      size="tiny"
                    />

                    <Popup
                      trigger={
                        <span>
                          <Button
                            size="tiny"
                            className="btn-middle"
                            onClick={async () => {
                              await mContainer.takeSnapshot(audioRecorder, wvContainer);
                              await this.onInsertPasteBtnClick(audioRecorder, wvContainer)
                              // mContainer.takeSnapshot(audioRecorder, wvContainer);
                            }}
                            disabled={
                              audioRecorder.isRecording() ||    // is recording
                              audioRecorder.getWaveMapSize() <= 0 ||    // no recording
                              audioRecorder.getClipboard().length === 0 ||
                              wvContainer.getSelection() == null ||     // no selection
                              // ( wvContainer.getPointSelectionFlag() && wvContainer.getSelection().start > audioRecorder.getRecordingDuration())  ||      // point selection is out of bound, no need to disable
                              // wvContainer.getSelection().end - wvContainer.getSelection().start <= 0 ||   // selection is not positive length
                              audioRecorder.getLastPlayer() !== undefined ||    // some clip is playing/looping
                              audioRecorder.checkPlaying()
                            }
                          >
                            Paste
                          </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Paste"
                      position="bottom center"
                      inverted
                      size="tiny"
                    />

                    <Popup
                      trigger={
                        <span>
                          <Button className="btn-right" size="tiny" disabled={this.isEffectsDisabled(audioRecorder)} onClick={e => this.reverse(audioRecorder, wvContainer)}> Reverse </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Reverse"
                      position="bottom center"
                      inverted
                      size="tiny"

                    />
                     {/* <Popup
                      trigger={
                        <span>
                          <Button className="btn-right" size="tiny" 
                           disabled={this.isEffectsDisabled(audioRecorder) || wvContainer.getSelection().end - wvContainer.getSelection().start <= 0}
                          onClick={e => this.reverse(audioRecorder, wvContainer)}> Repeat </Button>
                        </span>
                      }
                      className="fitted-padding"
                      content="Reverse"
                      position="bottom center"
                      inverted
                      size="tiny"
                    /> */}
                  </Button.Group>
                </Menu.Item>
                <MultiplyInput
                  header="Repeat"
                  disabled={this.isEffectsDisabled(audioRecorder) || wvContainer.getSelection().end - wvContainer.getSelection().start <= 0 || this.state.currentlyUpdatingRepeat}
                  onTimesClick={
                    async () => {
                      let val = Number(this.state.inputRepeatTime)
                      if(wvContainer.getSelectionLength(audioRecorder.getSampleRate())* val <= 100000 && !this.state.currentlyUpdatingRepeat ) {
                        // if (true || (Number.isInteger(val) && val > 0 && val <= MAX_REPEAT_TIMES) ) {
                          this.setState({currentlyUpdatingRepeat : true},async () => {
                            await mContainer.takeSnapshot(audioRecorder, wvContainer);
                            await this.onRepeatBtnClick(audioRecorder, wvContainer);
                            
                            this.setState({currentlyUpdatingRepeat : false})
                          })
                        // } else {
                        //   this.showErrorDialog(`The repeat field should be an Integer number between 0 and ${MAX_REPEAT_TIMES}`)
                        // }
                      } else {
                        this.showErrorDialog("The repeat length should be less than 100s")
                      }
                      }
                      

                  }
                  onEnter={
                    async () => {
                      let val = Number(this.state.inputRepeatTime)
                      if(wvContainer.getSelectionLength(audioRecorder.getSampleRate())* val <= 100000 && !this.state.currentlyUpdatingRepeat ) {
                        await mContainer.takeSnapshot(audioRecorder, wvContainer);
                        await this.onRepeatBtnClick(audioRecorder, wvContainer);
                      } else {
                          this.showErrorDialog("The repeat length should be less than 100s")
                        }
                      }
                    }
                  // }
                  onChange={
                    e => this.handleRepeatTimeChange(e)
                  }
                  getValue={() => this.state.inputRepeatTime}
                /> 
                <PlusMinusInput
                  header="Speed"
                  disabled={this.isEffectsDisabled(audioRecorder)}
                  onMinusClick={async () => {
                    let val = Number(audioRecorder.getResetResampleDisplay()
                      ? audioRecorder.getResampleRatio() / 1000
                      : this.state.inputSpeed);
                    if (val < 0.2 || val > 4) {
                      this.showErrorDialog("The value for the Speed field should be between 0.1 and 4")
                      return;
                    }
                    await mContainer.takeSnapshot(audioRecorder, wvContainer);
                    await this.resample(audioRecorder, wvContainer, (val - 0.1) * 1000);
                    this.setState({ inputSpeed: audioRecorder.getResampleRatio() / 1000 });
                    // mContainer.takeSnapshot(audioRecorder, wvContainer);

                  }}
                  onPlusClick={async () => {
                    let val = Number(audioRecorder.getResetResampleDisplay()
                      ? audioRecorder.getResampleRatio() / 1000
                      : this.state.inputSpeed);
                    if (val < 0.1 || val > 3.9) {
                      this.showErrorDialog("The value for the Speed field should be between 0.1 and 4")
                      return;
                    }
                    await mContainer.takeSnapshot(audioRecorder, wvContainer);
                    await this.resample(audioRecorder, wvContainer, (val + 0.1) * 1000);
                    this.setState({ inputSpeed: audioRecorder.getResampleRatio() / 1000 });
                    // mContainer.takeSnapshot(audioRecorder, wvContainer);
                  }}
                  onChange={(e) => {
                    this.handleSpeedChange(e, audioRecorder)
                  }}
                  onEnter={
                    async () => {
                      if (Number(this.state.inputSpeed) >= 0.2 && Number(this.state.inputSpeed) <= 4.0) {
                        await mContainer.takeSnapshot(audioRecorder, wvContainer);
                        await this.resample(audioRecorder, wvContainer, Number(this.state.inputSpeed) * 1000);
                        // mContainer.takeSnapshot(audioRecorder, wvContainer);
                      } else {
                        this.showErrorDialog("The value for the Speed field should be between 0.1 and 4")
                        return;
                      }
                    }
                  }
                  getValue={() => {
                    let val = audioRecorder.getResetResampleDisplay() ?
                      audioRecorder.getResampleRatio() / 1000 :
                      this.state.inputSpeed;
                    if (typeof val === 'string') {
                      return val.slice(0, 5);
                    }
                    val = val.toFixed(4);
                    return +val;
                  }}
                />
                <PlusMinusInput
                  header="Length (ms)"
                  disabled={this.isEffectsDisabled(audioRecorder, true) || !(wvContainer.getSelection().end - wvContainer.getSelection().start)}
                  onMinusClick={async () => {
                    let val = Number(audioRecorder.getResetLengthDisplay() ?
                      +(wvContainer.getSelectionLength(audioRecorder.getSampleRate()).toFixed(2)) :
                      this.state.inputLength)
                    const value = Math.log(val * 0.95) / Math.log(1 / scaleForShorteningSelection);
                    wvContainer.updateSelectionLength(audioRecorder, value, scaleForShorteningSelection, audioRecorder.getSampleRate());
                    wvContainer.setManualPtSelection(false);
                    this.setState({
                      inputLength: (((1 / scaleForShorteningSelection) ** value / 1000) * 1000).toFixed(2)
                    })
                  }}
                  onPlusClick={() => {
                    let val = Number(audioRecorder.getResetLengthDisplay() ?
                      +(wvContainer.getSelectionLength(audioRecorder.getSampleRate()).toFixed(2)) :
                      this.state.inputLength)
                    const value = Math.log(val * 1.05) / Math.log(1 / scaleForShorteningSelection);
                    wvContainer.updateSelectionLength(audioRecorder, value, scaleForShorteningSelection, audioRecorder.getSampleRate());
                    wvContainer.setManualPtSelection(false);
                    this.setState({
                      inputLength: (((1 / scaleForShorteningSelection) ** value / 1000) * 1000).toFixed(2)
                    })
                  }}

                  onEnter={
                    () => {
                      const value =
                        Math.log(Number(this.state.inputLength)) /
                        Math.log(1 / scaleForShorteningSelection);
                      wvContainer.updateSelectionLength(
                        audioRecorder,
                        value,
                        scaleForShorteningSelection,
                        audioRecorder.getSampleRate()
                      );
                      wvContainer.setManualPtSelection(false);
                    }
                  }
                  onChange={
                    e => this.handleLengthChange(e, audioRecorder)
                  }
                  getValue={() => {
                    if(audioRecorder.getSelectedClipId() === null) {
                      return 0;
                    }
                    let val = audioRecorder.getResetLengthDisplay() ?
                      +(wvContainer.getSelectionLength(audioRecorder.getSampleRate()).toFixed(2)) :
                      this.state.inputLength
                    if (typeof val === 'string') {
                      return val.slice(0, 5);
                    }
                    val = val.toFixed(4);
                    return +val;
                  }}
                />
               
               </>)
              }

              {/* TODO: reset times */}
              {/* Repeat function button */}
              {this.state.openPopUp === 'repeat' && ( //!isMobile &&
                <div>
                  <div
                    className="popup-mask"
                    onClick={() => {
                      this.setState({
                        openPopUp: ''
                      });
                    }}
                  ></div>
                  <div
                    style={{
                      top: '90px',
                      position: 'absolute',
                      background: 'white',
                      zIndex: 2,
                      left: '800px',
                      borderRadius: '.28571429rem',
                      padding: '10px'
                    }}
                  >
                    <label>
                      <input
                        type="number"
                        value={this.state.inputRepeatTime}
                        onChange={e => this.handleRepeatTimeChange(e)}
                      ></input>{' '}
                      times
                    </label>
                    <button
                      onClick={async () => {
                        await this.onRepeatBtnClick(audioRecorder, wvContainer);
                        mContainer.takeSnapshot(audioRecorder, wvContainer);
                      }}
                    >
                      enter
                    </button>
                  </div>
                </div>
              )}

              {!audioRecorder.isRecording() &&
                <ExportPane openPopupWarn={this.openPopupWarn} />
              }
              <Modal size={'tiny'} open={this.state.effectError}>
                <Modal.Header>Warning!</Modal.Header>
                <Modal.Content>
                  <p>
                    {this.state.effectErrorString}
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={() => this.setState({ effectError: false })}
                    icon="checkmark"
                    labelPosition="left"
                    content="I understand"
                  />
                </Modal.Actions>
              </Modal>
              {/* Modal for too long of recording duration warning */}
              <Modal size={'tiny'} open={this.state.warningOpen}>
                <Modal.Header>Warning!</Modal.Header>
                <Modal.Content>
                  <p>
                    Recordings longer than 5 minutes are unable to be saved or downloaded in order
                    to preserve performance.
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={() => this.closePopupWarn()}
                    icon="checkmark"
                    labelPosition="left"
                    content="I understand"
                  />
                </Modal.Actions>
              </Modal>

              {/* Modal for too many recordings warning */}
              <Modal size={'tiny'} open={this.state.warningOpen2}>
                <Modal.Header>Warning!</Modal.Header>
                <Modal.Content>
                  <p>
                    No more than 5 recordings are allowed.
                  </p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={() => this.closePopupWarn2()}
                    icon="checkmark"
                    labelPosition="left"
                    content="I understand"
                  />
                </Modal.Actions>
              </Modal>
            </Menu>
            {/* Renders the current pane beneath the menu */}
            {this.state.pane}

            {/* Buttons on the lefthand side(for mobile device users) */}
            <DeviceOrientation lockOrientation={'landscape'}>
              <Orientation orientation="landscape" alwaysRender={false}>
                {isMobile && false && (
                  <Menu vertical={true}>
                    <Menu.Item>
                      <Button
                        className="left-menu-button"
                        onClick={() => {
                          if (this.state.openPopUp === 'length') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'length'
                            });
                          }
                          // this.setState(state => ({ isLengthPopUpOpen: !state.isLengthPopUpOpen }));
                        }}
                      >
                        Length
                      </Button>
                    </Menu.Item>
                    {/* length = 1ms * (10/9) ^ slider_value */}
                    {this.state.openPopUp === 'length' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          <span> &nbsp;Selection Length: </span>
                          <Slider
                            min={0}
                            max={70}
                            value={
                              Math.log(
                                wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                              ) / Math.log(1 / scaleForShorteningSelection)
                            }
                            onChange={value => {
                              wvContainer.updateSelectionLength(
                                audioRecorder,
                                value,
                                scaleForShorteningSelection,
                                audioRecorder.getSampleRate()
                              );

                              this.setState({
                                inputLength: (
                                  ((1 / scaleForShorteningSelection) ** value / 1000) *
                                  1000
                                ).toFixed(2) // display 2 decimal points
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />

                          {/* <span>{wvContainer.getSelectionLengthString()}&nbsp;&nbsp;</span> */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetLengthDisplay()
                                ? wvContainer
                                  .getSelectionLength(audioRecorder.getSampleRate())
                                  .toFixed(2)
                                : //   Math.log(
                                //     wvContainer.getSelectionLength(audioRecorder.getSampleRate())
                                //   ) / Math.log(1 / scaleForShorteningSelection)
                                // ).toFixed(2)
                                this.state.inputLength
                            }
                            onChange={e => this.handleLengthChange(e, audioRecorder)}
                          ></input>{' '}
                          ms
                        </label>
                        <button
                          onClick={() => {
                            const value =
                              Math.log(this.state.inputLength) /
                              Math.log(1 / scaleForShorteningSelection);
                            wvContainer.updateSelectionLength(
                              audioRecorder,
                              value,
                              scaleForShorteningSelection,
                              audioRecorder.getSampleRate()
                            );
                            wvContainer.setManualPtSelection(false);
                            //console.log("setPointSelectionFlag(false)");
                          }}
                        >
                          enter
                        </button>
                      </div>
                    )}

                    <Menu.Item>
                      <Button
                        onClick={() => {
                          if (this.state.openPopUp === 'speed') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'speed'
                            });
                          }

                          // this.setState(state => ({ isSpeedPopUpOpen: !state.isSpeedPopUpOpen }));
                        }}
                        className="left-menu-button"
                      //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Speed
                      </Button>
                    </Menu.Item>
                    {this.state.openPopUp === 'speed' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          speed:
                          <Slider
                            min={200}
                            max={4000}
                            value={audioRecorder.getResampleRatio()}
                            onChange={value => {
                              this.resample(audioRecorder, wvContainer, value);
                              this.setState({
                                inputSpeed: audioRecorder.getResampleRatio() / 1000
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getResampleRatio() / 1000} */}
                        </div>

                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetResampleDisplay()
                                ? audioRecorder.getResampleRatio() / 1000
                                : this.state.inputSpeed
                            }
                            onChange={e => this.handleSpeedChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={() => {
                            this.resample(audioRecorder, wvContainer, this.state.inputSpeed * 1000);
                          }}
                        >
                          enter
                        </button>
                      </div>
                    )}
                    <Menu.Item>
                      <Button
                        onClick={() => {
                          // this.setState(state => ({ isAmplitudePopUpOpen: !state.isAmplitudePopUpOpen }));

                          if (this.state.openPopUp === 'amplitude') {
                            this.setState({
                              openPopUp: ''
                            });
                          } else {
                            this.setState({
                              openPopUp: 'amplitude'
                            });
                          }
                        }}
                        className="left-menu-button"
                      //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                      >
                        Amplitude
                      </Button>
                    </Menu.Item>
                    {this.state.openPopUp === 'amplitude' && (
                      <div
                        style={{
                          top: '11%',
                          position: 'absolute',
                          background: 'white',
                          zIndex: 1,
                          right: '50px',
                          borderRadius: '.28571429rem',
                          padding: '10px'
                        }}
                      >
                        <div>
                          amplitude:
                          <Slider
                            min={20}
                            max={4000}
                            value={audioRecorder.getAmplitudeRatio()}
                            onChange={value => {
                              this.changeAmplitude(audioRecorder, wvContainer, value);
                              this.setState({
                                // inputAmplitude: audioRecorder.getAmplitudeRatio() /
                                // 1000
                                inputAmplitude:
                                  constants.AMPLITUDE_LOG_RATIO **
                                  (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                              });
                            }}
                            tooltip={false}
                            className="slider"
                          />
                          {/* {audioRecorder.getAmplitudeRatio() / 1000} */}
                        </div>
                        <label>
                          <input
                            type="number"
                            value={
                              audioRecorder.getResetAmplitudeDisplay()
                                ? (
                                  constants.AMPLITUDE_LOG_RATIO **
                                  (audioRecorder.getAmplitudeRatio() / 1000 - 2)
                                ).toFixed(2)
                                : this.state.inputAmplitude
                            }
                            onChange={e => this.handleAmplitudeChange(e, audioRecorder)}
                          ></input>{' '}
                          x
                        </label>
                        <button
                          onClick={() => {
                            this.changeAmplitude(
                              audioRecorder,
                              wvContainer,
                              this.state.inputAmplitude,
                              true
                            );
                          }}
                        >
                          enter
                        </button>{' '}
                      </div>
                    )}

                    {/* <MenuItem>
                <Button onClick={() => this.toggleQuantizeSelection()}>Edit Selection</Button>
              </MenuItem> */}
                    <div
                      style={{
                        position: 'absolute',
                        display: this.state.isQuantizeSelectionPopUpOpen ? 'block' : 'none',
                        top: '90px',
                        left: '750px',
                        zIndex: '1',
                        padding: '10px'
                      }}
                    >
                      <div style={{ borderRadius: '.28571429rem' }}>
                        {/* <button onClick={() => this.newSelectionOnClick(wvContainer)}>
                    {' '}
                    new selection{' '}
                  </button> */}
                        <div>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.LEFT)
                            }
                          >
                            {' '}
                            &lt;-{' '}
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.RIGHT)
                            }
                          >
                            {' '}
                            -&gt;{' '}
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.LONGER)
                            }
                          >
                            longer
                          </button>
                          <button
                            className="selectionButtons"
                            onClick={() =>
                              this.editSelection(audioRecorder, wvContainer, EDIT.SHORTER)
                            }
                          >
                            shorter
                          </button>
                        </div>
                        {/* <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 4)}
                  >
                    1/4s
                  </button>
                  <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 3)}
                  >
                    1/3s
                  </button>
                  <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1 / 2)}
                  >
                    1/2s
                  </button>
                  <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 2 / 3)}
                  >
                    2/3s
                  </button>
                  <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 3 / 4)}
                  >
                    3/4s
                  </button>
                  <button
                    className="selectionButtons"
                    onClick={() => this.quantizeSelection(audioRecorder, wvContainer, 1)}
                  >
                    1s
                  </button> */}
                      </div>
                    </div>

                    <Menu.Item>
                      <Button
                        //disabled={this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0}
                        onClick={e => this.reverse(audioRecorder, wvContainer)}
                        className="left-menu-button"
                      >
                        Reverse
                      </Button>
                    </Menu.Item>

                    {/* Reverb crashed (even for the previous versions that used to work), so Reverb is temporarily disabled. */}
                    {/*<Menu.Item>
                  <Button
                    toggle
                    disabled={
                      this.state.isPlaying || audioRecorder.getSelectionPlayer().length !== 0
                    }
                    active={audioRecorder.isReverbOn()}
                    onClick={() => this.applyReverb(audioRecorder, wvContainer)}
                    className="left-menu-button"
                  >
                    Reverb
                  </Button>
                  </Menu.Item>*/}
                  </Menu>
                )}
              </Orientation>
            </DeviceOrientation>
          </div>
        )}
      </Subscribe>
    );
  }
}

export default TopMenu;