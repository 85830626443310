import { Container } from 'unstated';
import { clamp } from '../util';

const INITIAL_1_SEC_SCALE = 0.003906252*40; // Initial scale



class WaveViewerContainer extends Container {
  constructor() {
    super();

    this.state = {
      end: 0,
      scale: INITIAL_1_SEC_SCALE,
      selection: {
        // selection's start and end are in number of sample points
        start: 0,
        end: 0
      },
      prevSelection: {
        // settings of the selection at the beginning of when the current selection is played
        // used to distinguish from selection so that we can keep playing to the old end and maintain the tempo
        start: 0,
        end: 0
      },
      pointSelection: false,
      selectionForRecorder: null, // hacky variable for now /* IS THIS NEEEDED */
      audioDataLength: 0,
      lockWave: true,
      cv: null,
      numViewers: 1, //  NOTE: Number of waveViewer panes. For the future
      currentSelectionPlayTime: 0, // offset in milliseconds from the beginning of the selection
      displayBar: false,
      playInterval: null,
      selectionLengthString: '',
      newSelection: false,
      keepPlayingToOldEnd: false, // whether we want to play until the end of the previous selection
      startTime: null,
      isTranslating: false,
      manualPtSelection: true, // manually select interval (useful for tablet users)
      //temp1:0,
    };
  }

  setIsTranslating(value) {
    this.setState({
      isTranslating: value
    });
  }

  getIsTranslating() {
    return this.state.isTranslating;
  }

  getStartTime() {
    return this.state.startTime;
  }

  setStartTime(value) {
    this.setState({
      startTime: value
    });
  }

  getDisplayBar() {
    return this.state.displayBar;
  }

  setDisplayBar(value) {
    this.setState({
      displayBar: value
    });
  }

  setKeepPlayingToOldEnd(value) {
    this.setState({
      keepPlayingToOldEnd: value
    });
  }

  getKeepPlayingToOldEnd() {
    return this.state.keepPlayingToOldEnd;
  }

  /**
   * @name start()
   * @description calculates the property value for start anytime it is referenced
   *
   * @returns the value of start
   */
  start(half, end) {
    const { cv, scale } = this.state;
    if (cv) {
      if (half) {
        return Math.max(0, end - Math.ceil(cv.width / scale / 2) - 1);
      }
      return Math.max(0, end - Math.ceil(cv.width / scale) - 1);
    }
    return 0;
  }

  /**
   * @name setSelection
   * @description Function called from Wave viewer to set the selection in the state for the player to play
   *              Effects: Updates the state and sets the selection to the selection {start, end}
   */
  setSelectionForRecorder() {
    const { selection } = this.state;
    const w = selection.end - selection.start;
    this.setState({
      selectionForRecorder: w ? selection : null
    });
  }

  getSelectionForRecorder() {
    console.log(this.state.selectionForRecorder);
    return this.state.selectionForRecorder;
  }

  getSelectionCenter(cv) {
    const { selection, scale } = this.state;
    const centerSelection = Math.floor((selection.start + selection.end) / 2);
    return (centerSelection - this.getStart(cv)) * scale;
  }

  async updateSelection(offset, cv, mouseType, audioRecorder) {
    const { selection } = this.state;

    // unique case for mouseup
    if (mouseType === 'mouseup') {
      /* NOTE: Hacky workaround: do not change the value of selection but just call setState to force a re-render 
         amongst subscribed components */
      this.setState({
        selection
      });
      return selection;
    }

    if (mouseType === 'multiend') {
      this.setState(state => {
        state.selection.start = 0;
        state.selection.end = 0;
      });
      return selection;
    }
    
    // other cases for mousedown, mousemove
    const newSelectionStart = this.getStart(cv) + offset / this.getScale();
    console.log("offset x ",newSelectionStart, mouseType)
    if (mouseType === 'mousedown') {
      // clears selection
      if (selection.end > selection.start && Math.abs(selection.start - newSelectionStart) <= 1/this.getScale()) {
        console.log("selection clearing")
        // this.setState(state => {
          this.state.selection.start = newSelectionStart;
          this.state.selection.end = newSelectionStart;
        // });
      }
      // point selection or initial selection before move
      else {
        this.state.selection.start = newSelectionStart;
        this.state.selection.end = newSelectionStart + (this.getScale() >= 4 ? 0.2 : 1);
      }
    } else if (mouseType === 'mousemove') {
      if (newSelectionStart > selection.start) {
        // await this.setState(state => {
          // eslint-disable-next-line no-param-reassign
          this.state.selection.end = newSelectionStart;
        // });
        //console.log(newSelectionStart);
      } else {
        // await this.setState(state => {
          // eslint-disable-next-line no-param-reassign
          this.state.selection.start = newSelectionStart;
        // });
      }
    } else if (mouseType === 'pointmove') {
      if (newSelectionStart > selection.start) {
        // await this.setState(state => {
          // eslint-disable-next-line no-param-reassign
          this.state.selection.start = newSelectionStart - 1;
          this.state.selection.end = newSelectionStart;
        // });
      } else {
        // await this.setState(state => {
          // eslint-disable-next-line no-param-reassign
          this.state.selection.start = newSelectionStart;
          this.state.selection.end = newSelectionStart + 1;
        // });
      }
    }

    return this.state.selection;
  }

  getNumViewers() {
    return this.state.numViewers;
  }

  getCanvasReference() {
    return this.state.cv;
  }

  setCanvasReference(cv) {
    this.setState({
      cv
    });
  }

  getCanvasCenter(cv = this.state.cv) {
    return Math.floor(cv.width / 2);
  }

  seekToLive(totalSamplePoints) {
    if (totalSamplePoints) {
      this.setState({
        end: totalSamplePoints
      });
    }
  }

  setPointSelectionFlag(toggle) {
    this.setState({
      pointSelection: toggle
    });
  }

  getPointSelectionFlag() {
    return this.state.pointSelection;
  }

  /**
   * @name clearSelection
   * @description Clears the selection stored in the container state
   *              Effects: Clears the selection so that fresh selections can occur on new recordings
   */
  clearSelection() {
    this.setState({
      selection: {
        start: 0,
        end: 0
      }
      
    });
    this.state.selection.start = 0;
    this.state.selection.end = 0;
  }

  getSelection() {
    return this.state.selection;
  }

  setSelection(selection, sampleRate) {
    this.setState({
      selection
    });
    this.setSelectionLengthString(((selection.end - selection.start) / sampleRate) * 1000);
  }

  getPrevSelection() {
    return this.state.prevSelection;
  }

  setPrevSelection(prevSelection) {
    this.setState({
      prevSelection
    });
  }

  /**
   * Update the end of the waveviewer so as to scroll it forward whenever
   * the buffers inside the audioRecorder get updated
   * @param {Number} newBuffersLength Size of the new incoming buffers
   */
  async updateEnd(newBuffersLength) {
    let { end } = this.state;
    if (newBuffersLength < end) return;

    const { scale, audioDataLength } = this.state;

    // auto scroll if scrolled to end
    if (Math.abs(end - audioDataLength) < 64 / scale || audioDataLength  <= newBuffersLength) {
      end = newBuffersLength;
    }

    /* NOTE: DO NOT REPLACE THIS WITH this.setState 
       setState forces a re-render and that leads to less buffers in the state
    */
    this.state.audioDataLength = newBuffersLength;
    this.state.end = end;
  }

  /**
   * NOTE: Takes a canvas reference so that it can be used with different canvas refs
   */
  getStart(cv) {
    const { end, scale } = this.state;
    return Math.max(0, end - Math.ceil(cv.width / scale) - 1);
  }

  getEnd() {
    return this.state.end;
  }

  setEnd(newEnd) {
    this.setState({
      end: newEnd
    });
  }

  getScale() {
    return this.state.scale;
  }

  setScale(newScale) {
    console.log('setting scale', newScale)
    if(newScale === undefined) {
      newScale = INITIAL_1_SEC_SCALE;
    }
    this.setState({
      scale: newScale
    });
  }

  /**
   * @name getTimeData
   * @deprecated
   * @description Given the total recording and the sampling rate, getTimeData calculates data points for the given range
   *              (startTime, endTime)
   * @param {Array[Float32Array[1024]]} totalRecording total audio recording from the AudioRecorder component
   * @param {Number} sampleRate Sample rate as defined by the AudioContext (constant value of 44100) of the web audio API
   * @param {Number} startIndex start time in sample points
   * @param {Number} endIndex end time in sample points
   * @returns {(Array[Number])} Array containing data points of recording for the range (startTime, endTime)
   */

  // eslint-disable-next-line class-methods-use-this
  getTimeData(totalRecording, sampleRate, startIndex, endIndex) {
    if (totalRecording && totalRecording !== undefined && totalRecording.length > 0) {
      const elementNum = totalRecording[0].length; // always fixed to 1024
      const flatbuffer = [];

      let startBufNum = Math.floor(startIndex / elementNum);
      let startBufRes = startIndex % elementNum;
      // var startBufRes = 0;

      let endBufNum = Math.floor(endIndex / elementNum);
      let endBufRes = endIndex % elementNum;
      // var endBufRes = 0;

      if (endIndex * sampleRate > totalRecording.length * elementNum) {
        endBufNum = totalRecording.length - 1;
        endBufRes = elementNum - 1;
      }

      while (startBufNum <= endBufNum) {
        // !(startBufNum == endBufNum && startBufRes == endBufRes)
        // only when startBufNum == endBufNum, check that startBufRes <= endBufRes
        if (startBufNum === endBufNum) {
          if (startBufRes > endBufRes) {
            break;
          }
        }

        // infinite loop can happen if startBufRes is not in range [0, elementNum]
        if (startBufRes > elementNum - 1) {
          break;
        }

        if (totalRecording[startBufNum]) {
          // check to avoid crash on very first load
          flatbuffer.push(totalRecording[startBufNum][startBufRes]);
        } else {
          // push y = 0 onto the flatbuffer
          flatbuffer.push(0);
        }

        // reset startBufNum to the next buffer
        if (startBufRes === elementNum - 1) {
          startBufNum += 1;
          startBufRes = 0;
        } else {
          startBufRes += 1;
        }
      }
      return flatbuffer;
    }
    return [];
  }

  getAudioDataLength() {
    return this.state.audioDataLength;
  }

  getLockWave() {
    return this.state.lockWave;
  }

  getCurrentSelectionPlayTime() {
    return this.state.currentSelectionPlayTime;
  }

  setCurrentSelectionPlayTime(t) {
    this.setState({
      currentSelectionPlayTime: t
    });
  }

  getDisplayBarApproxIndex(sampleRate) {
    const start = this.start(false, this.getEnd());
    const {cv} = this.state;
    const offset = this.getScale() *
        (this.state.prevSelection.start -
          start +
          (this.getCurrentSelectionPlayTime() / 1000) * sampleRate);
    return this.getStart(cv) + offset/this.getScale();
  }
  getManualPtSelection() {
    return this.state.manualPtSelection;
  }

  setManualPtSelection(toggle) {
    this.setState({
      manualPtSelection: toggle
    });
  }

  skip(totalSamplePoints, delta, cv = this.state.cv) {
    if (!cv) {
      return;
    }
    const end = this.getEnd();
    const scale = this.getScale();

    const minEnd = Math.min(cv.width / scale, totalSamplePoints);
    const newEnd = clamp(Math.floor(end + delta / scale), minEnd, totalSamplePoints);
    this.setEnd(newEnd);
  }

  skipToStart(totalSamplePoints, cv = this.state.cv) {
    if (!cv) {
      return;
    }
    const scale = this.getScale();
    const minEnd = Math.min(cv.width / scale, totalSamplePoints);
    this.setEnd(minEnd);
  }

  zoom(
    totalSamplePoints,
    recordingSamplePoints,
    isRecording,
    delta,
    cv = this.state.cv,
    xPosition = this.getCanvasCenter()
  ) 
  {
    const end = this.getEnd();
    const scale = this.getScale();
    let temp1;
    let curWidth;
    let tempScale;
    const fixed = end - (1 - xPosition / cv.width) * (cv.width / scale);
    curWidth = scale * totalSamplePoints;
    //const newScale = clamp(scale * 1.01 ** delta, 1 / 256, 16);
    temp1 = cv.width / totalSamplePoints;
    // tempScale = clamp(scale * 1.01 ** delta, 1 / (5*256), 16);
    if (curWidth < cv.width - 1) {
      tempScale = clamp(scale * 1.01 ** delta, 1 / (256), 16);
    } else {
      tempScale = clamp(scale * 1.01 ** delta,  Math.max(1/(10*256),temp1), 16);
    }
    const newScale = tempScale;
    //if(temp1>newScale){ myreturn = temp1;}else{myreturn = newScale;}
    const minEnd = Math.min(cv.width / newScale, totalSamplePoints);
    //const minEnd = Math.min(cv.width / scale, totalSamplePoints);
    let newEnd;

    if (isRecording) {
      // recording
      newEnd = Math.max(end, totalSamplePoints);
    } else {
      newEnd = clamp(
        fixed + (1 - xPosition / cv.width) * (cv.width / newScale),
        minEnd,
        totalSamplePoints
      );
    }

    this.setState({
      scale: newScale,
      end: parseInt(newEnd, 10),
    })
  }

  // return the time duration of the selection in milliseconds
  getSelectionLength(sampleRate) {
    const selection = this.getSelection();
    const mslength = ((selection.end - selection.start) / sampleRate) * 1000;
    return mslength;
  }

  setSelectionLengthString(mslength) {
    let selectionLengthString = '';

    if (mslength > 1000) {
      selectionLengthString = `${(mslength / 1000).toFixed(2)}s`;
    } else {
      selectionLengthString = `${mslength.toFixed(2)}ms`;
    }

    this.setState({
      selectionLengthString
    });
  }

  getSelectionLengthString() {
    return this.state.selectionLengthString;
  }

  updateSelectionLength(audioRecorderContainer, value, scaleForShorteningSelection, sampleRate) {
    this.originselection = this.getSelection();

    const updateSelection = {
      start: this.originselection.start,
      end:
        this.originselection.start +
        ((1 / scaleForShorteningSelection) ** value / 1000) * sampleRate
    };

    this.setState(
      {
        selection: updateSelection
      },
      () => {
        this.setSelectionLengthString(this.getSelectionLength(sampleRate));
      }
    );
  }

  setNewSelection(b) {
    this.setState({
      newSelection: b
    });
  }

  getNewSelection() {
    return this.state.newSelection;
  }
}

export default WaveViewerContainer;
