/* eslint-disable no-param-reassign */
import Tone from 'tone';
import { Container } from 'unstated';

import UNSTATED from 'unstated-debug';
import { constants } from '../constants';
import { MicStateEnum } from '../enums';
import Resampler from '../util/Resampler';
// import {cloneDeep} from 'lodash';

import { isMobile } from 'mobile-device-detect';
import { MAX_NUM_WAVES } from '../components/WaveViewer/WaveViewer';

/* Constant variables */
const intervalForCheckingMouse = 50; //in milliseconds
const BUFFER_CLIP_LENGTH = 2048;

/* Wave object */
let currID = 0; // Counter for generating wave ID
/**
 * Constructor for a wave object
 */ 
export function Wave(audioData, xPos=0, yPos=0, id=undefined ){
  // if id is not given use curr id and increment it, otherwise use given id
  if(id === undefined) {
    this.id = currID;
  } else {
    this.id = id;
  }
  this.audioData = audioData;
  this.xPos = xPos;
  this.yPos = yPos;
  if(id === undefined) {
    currID++;
  }
}

//TODO: Remove all instances of clip in code since its useless and causes wasteful computation and copies

/**
 * BufferedArray - We use this specific array to store audio data and not
 * normal javascript arrays since they are inefficient in cases of slicing and storing
 * data. The class has several functions and generally follows the copy on write paradigm
 */
export class BufferedArray {
  constructor(initialCapacity = 0, buffer=null) {
    this.buffer = buffer ? buffer: new Float32Array(initialCapacity);
    this.length = buffer ? buffer.length: 0;
    this.capacity = initialCapacity;
  }

  /**
   * fillCap fills the value val till the capcity
   * @param {float} val 
   * @returns current reference to the array
   */
  fillCap(val) {
    for(let i = 0; i < this.buffer.length; i++) {
      this.buffer[i] = val;
    }
    this.length = this.buffer.length;
    return this;
  }

  /**
   * Use this function if you want to copy , = copies by reference in js
   * @returns a new copy of the current array, doesn't add additional capacity
   */
  copy() {
    let buf = new Float32Array(this.length);
    buf.set(this.getCurrentBuffer());
    return new BufferedArray(buf.length, buf);
  }

  /**
   * In place map function to avoid making extra copies
   * @param {function} callbackfn 
   * @returns 
   */
  map(callbackfn) {
    for(let i = 0; i< this.length; i++) {
      this.buffer[i] = callbackfn(this.buffer[i], i);
    }
    return this;
  }

  /**
   * Concats the array, if capacity required is more, initializes a new buffer with required capacity
   * @param {array of floats} arr 
   * @returns 
   */
  concat(arr) {
    if(this.length + arr.length > this.capacity) {
      let buffer = new Float32Array(2* (this.length + arr.length ));
      buffer.set(this.buffer.subarray(0,this.length));
      this.buffer = buffer;
      this.capacity = buffer.length;
    }
    let j = 0;
    for(let i = this.length; i < Math.min(this.capacity, this.length + arr.length); i++) {
      this.buffer[i] = arr[j++];
    }
    this.length = Math.min(this.capacity, this.length + arr.length)
    return this;
  }

  /**
   * @returns Internal Float32 buffer reference for function which takes the Float32Array
   */
  getCurrentBuffer() {
    return this.buffer.subarray(0, this.length);
  }

  /**
   * Slice like js array.slice but doesn't create a copy, an edit on slice is an edit in the actual array
   * @param {Int?} start 
   * @param {Int?} end 
   * @returns reference to the sliced internal buffer wrapped in the buffered array
   */
  slice(start, end) {
    if (!start) {
      start = 0;
    }
    if (!end && end !== 0) {
      end = this.length;
    }
    let sliceBuf = this.buffer.subarray(start,end);
    return new BufferedArray(end - start, sliceBuf)
  }

  /**
   * Reverse inplace
   * @returns 
   */
  reverse() {
    this.getCurrentBuffer().reverse();
    return this;
  }

  /**
   * Gets the value at that index, mirrors arr[idx]
   * @param {Int} idx 
   * @returns 
   */
  get(idx) {
    if (idx >= this.length) {
      return 0;
    }
    return this.buffer[idx];
  }

  /**
   * Set value val at idx
   * @param {Int} idx 
   * @param {Float32} val 
   */
  set(idx, val) {
    if (idx >= this.length) {
      throw new Error(`Index out of bounds , index = ${idx} , length = ${this.length}` )
    }
    this.buffer[idx] = val;
  }

  /**
   * calls foreach on internal buffer
   * @param {function} callbackFn 
   */
  forEach(callbackFn) {
    this.buffer.subarray(0,this.length).forEach(callbackFn);
  }

  /**
   * calls findIndex on internal buffer
   * @param {function} callbackFn 
   * @returns Int
   */
  findIndex(callbackFn) {
    return this.buffer.subarray(0,this.length).findIndex(callbackFn);
  }

  prependZeros(numZeros) {
    
  }

  /**
   * Iterator for for each loop in javascript
   */
  [Symbol.iterator]() {
    let index = 0;
    let len = this.length;
    let data = this.buffer;
    return {
      next() {
        if (index < len) {
          return { value: data[index++], done: false };
        } else {
          return { done: true };
        }
      }
    };
  }
}


class AudioRecorderContainer extends Container {
  constructor() {
    super();

    /* Set sampleRate to be 44100 in the audioContext */
    if (isMobile) {
      Tone.setContext(
        new AudioContext({
          latencyHint: 'interactive',
          sampleRate: constants.SAMPLE_RATE_MOBILE
        })
      );
    } else {
      Tone.setContext(
        new AudioContext({
          latencyHint: 'interactive',
          sampleRate: constants.SAMPLE_RATE
        })
      );
    }
    /* Setup web audio context, input node and the monitor node */
    const acx = Tone.context.rawContext;
    const inputNode = acx.createGain();
    const monitorNode = acx.createGain();
    const processor = acx.createScriptProcessor(undefined, 1, 1);
    const reverbNode = acx.createConvolver();

    // fix for increasing reverb volume
    const reverbGainNode = acx.createGain();
    reverbGainNode.gain.value = 1.3;

    this.state = {
      waveMap: new Map(), // type: Map<id, Wave>, see above for Wave object specifications
      audioData: new BufferedArray(1024), // Array of audio data, was buffers[][] before
      clip: null, // concat
      selectedClipId: null,
      selectedClipIndex: null,
      recordingClipId: null,
      acx,
      inputNode,
      monitorNode,
      processor,
      reverbNode,
      reverbGainNode,
      mic: {
        permission: MicStateEnum.DISABLED,
        stream: null,
        gain: 0,
        showInstructionsModal: false
      },
      selectionPlayers: [],
      recording: false,
      hasRecorded: false,
      recordingDuration: 0,
      isReverbOn: false,
      outputVolume: 0, // in terms of dB
      // pausedPlayersTimes: [],
      playInterval: [],
      resampleRatio: 1000,
      amplitudeRatio: 2000,

      resetResampleDisplay: true,
      resetAmplitudeDisplay: true,
      resetLengthDisplay: true,
      start: 0,
      end: 0,
      donePlaying: true,
      speed: constants.SAMPLE_RATE / (1000 / intervalForCheckingMouse), // 2205 // 44100 in 20 steps( 20 = 1000 / intervalfor checkingmouse)
      clipboard: [],
      rightMenuOpen: false,
      isPlaying: false,
    };

    this.originalSpeedData = null;
    this.originalEnd = null;
    this.originalAmplitudeData = null;
    /* Connect the inputNode to the monitorNode */
    inputNode.connect(monitorNode);

    /* Create Reverb */
    this.createReverb();
  }

  startNewClip() {
    const clip = this.createClip();
    if (!clip) return null;

    this.setState({
      audioData: new BufferedArray(1024),
    });
  }

  /**
   * @description returns the specific portion of all audio clips for all tracks in audioData array.
   *  Mainly used for audio PLAY functionality. 
   * 
   * Parameters:
   * @param start - the starting sample of the desired clip
   * @param end   - the ending sample of the desired clip
   * @returns Promise containing the audioData as a buffer.
   */
  getPlayClips(start, end) {
    const { waveMap } = this.state;
    return new Promise(async resolve => {
      const playClips = {};
      // for each audioData in waveMap, get the clip and store it in playClips   
      waveMap.forEach(async wave => {
        await this.getBuffer(start, end, wave.audioData)
          .then(audioData => playClips[wave.id] = audioData);
      })
      
      this.setState({ audioData: this.getLastAudioData() });
      return resolve(playClips);
    });
  }
  
  bindWaveViewer(wvContainer) {
    this.wvContainer = wvContainer;
  }

  updateOutputVolume(newVolume) {
    this.setState({
      outputVolume: newVolume
    });
  }

  setIsPlaying(playing) {
    this.setState({isPlaying: playing});
  }

  checkPlaying() {
    return this.state.isPlaying;
  }

  getOutputVolume() {
    return this.state.outputVolume;
  }

  getRecordingDuration() {
    return this.state.recordingDuration;
  }

  getRawWaveMap(){
    return this.state.waveMap;
  }

  appendSelectionPlayer(player) {
    // Append to selection players
    this.setState(state => {
      state.selectionPlayers.push(player);
      return state;
    });
  }

  getSelectionPlayer() {
    return this.state.selectionPlayers;
  }

  removeSelectionPlayer(player) {
    this.setState({
      selectionPlayers: this.state.selectionPlayers.filter(pl => pl.tp !== player)
    });
  }

  // pops the most recently added player off and stops it
  stopLastPlayer() {
    const { selectionPlayers, playInterval } = this.state;
    if (selectionPlayers.length > 0) {
      const interval = playInterval.pop();
      clearInterval(interval);
      this.setState({
        playInterval
      });

      const tpwrapper = selectionPlayers.pop();
      tpwrapper.tp.stop();

      // set the state with the empty array of players
      this.setState({
        selectionPlayers
      });
    }
  }

  // Gets last player (most recently added player) in the selectionPlayers list
  getLastPlayer() {
    const { selectionPlayers } = this.state;
    return selectionPlayers.slice(selectionPlayers.length - 1, selectionPlayers.length)[0];
  }

  stopAllSelectionPlayers() {
    const { selectionPlayers } = this.state;

    // iterate through the players array and stop each player from playing
    while (selectionPlayers.length > 0) {
      const player = selectionPlayers.pop();
      player.tp.stop();
    }

    // set the state with the empty array of players
    this.setState({
      selectionPlayers
    });
    this.state.playInterval.map(i => clearInterval(i));
    // for (const i of this.state.playInterval) {
    //   clearInterval(i);
    // }
  }

  getAudioDataLength() {
    return this.state.audioData.length;
  }

  /**
   * @name getLongestAudioDataLength()
   * @description Gets the length of longest audioData among all recordings.
   * @returns The length of longest audioData. 
   */
  getLongestAudioDataLength() {
    let lengths = [];
    this.state.waveMap.forEach(item => lengths.push(item.audioData.length));
    lengths.push(this.state.audioData.length);
    return Math.max(...lengths);
  }

   /**
   * @name getBuffer()
   * @description returns the specific portion of the audio clip and is mainly used for Recording
   *
   * Parameters:  @param start - the starting sample of the desired clip
   *              @param end   - the ending sample of the desired clip
   * @returns Promise containing the audioData as a buffer.
   */
  getBuffer(start = 0, end, audioData=null) {
    // eslint-disable-next-line no-param-reassign
    const { acx } = this.state;

    return new Promise(resolve => {
      const clip = this.createClip(audioData);
      end = end || clip?.length;
      const data = clip.getChannelData(0).subarray(start, end);
      const buf = acx.createBuffer(1, end - start, acx.sampleRate);
      buf.copyToChannel(data, 0, 0);

      // set the clip of all the buffers to the state
      this.setState({
        clip
      });

      return resolve(buf);
    });
  }

  /**
   * Combines audioData for all waves together to create a sum buffer
   * @returns a buffer containing all recordings
   */
  getSumBuffers(){
    
    return new Promise(resolve => {
      if(this.getWaveMapSize === 0) return resolve([]);

      const { waveMap, acx } = this.state;
      const audioDataLength = this.getLongestAudioDataLength();
      let sumData = new Float32Array(audioDataLength).fill(0.0);

      waveMap.forEach(wave => {
        wave.audioData.forEach((val,idx) => {
          sumData[idx] += val;
        })
      })
      var firstNonZeroIndex = sumData.findIndex(val=>val !== 0)
      sumData = sumData.slice(firstNonZeroIndex);
      const buf = acx.createBuffer(1, sumData.length, acx.sampleRate);
      buf.copyToChannel(sumData, 0, 0);

      return resolve(buf);
    });
  }

  /**
   * @name deleteSelection()
   * @description deletes the selected portion of the recording
   *
   * Parameters:  @param start - the starting sample of the desired clip
   *              @param end   - the ending sample of the desired clip
   */   
  deleteSelection(start, end, wvContainer) {
    const { audioData } = this.getSelectedClipAndData();

    start = start || 0;
    end = end || audioData.length;
    start = Math.min(Math.floor(start), audioData.length);
    end = Math.min(Math.floor(end), audioData.length);
    
    this.replaceSelection(start, end, [], wvContainer);

    wvContainer.clearSelection();
  }

  /**
   * @name deleteButSelection()
   * @description deletes the selected portion of the recording
   *
   * Parameters:  @param start - the starting sample of the desired clip
   *              @param end   - the ending sample of the desired clip
   */
  deleteButSelection(start, end, wvContainer) {
    let {waveMap, selectedClipId, audioData } = this.getSelectedClipAndData()
    let newAudioData = audioData
    start = start || 0;
    end = end || audioData.length;
    start = Math.min(Math.floor(start), audioData.length);
    end = Math.min(Math.floor(end), audioData.length);
    for(let i = 0; i < end; i++){
      if(i<start) newAudioData.set(i,0);
    }
    newAudioData = newAudioData.slice(0,end);
    // wvContainer.clearSelection();
    waveMap.get(selectedClipId).audioData = newAudioData;
  }
  // resample from start to end, also update selection in wvContainer
  resample(value, wvContainer, start, end, tempEnd) {
    this.resetAmplitude();
    let { clip, waveMap, selectedClipId, audioData } = this.getSelectedClipAndData()
    let { acx } = this.state;
    if(!audioData) return;
    let data = audioData;
    start = start || 0;
    end = end || audioData.length;
    start = Math.floor(start);
    end = Math.floor(end);
    
    if (this.originalSpeedData == null) {
      data = audioData;
      this.originalSpeedData = data.copy();
      this.originalEnd = end;
    } else {
      data = this.originalSpeedData.copy();
      end = this.originalEnd;
    }

    const arr1 = data.slice(0, start);

    const resampler = new Resampler(acx.sampleRate, acx.sampleRate / value, 1, end - start);
    console.log("Resmaple start, " ,Date.now())
    let arr2 = resampler.resample(data.slice(start, end).getCurrentBuffer());
    console.log("Resmaple end, " ,Date.now());
    arr2 = new BufferedArray(arr2.length,arr2);
    const newend = start + arr2.length;
    const newselection = {
      start,
      end: newend
    };

    const arr3 = data.slice(end);

    data = new Float32Array(arr1.length + arr2.length + arr3.length);
    data.set(arr1.getCurrentBuffer());
    data.set(arr2.getCurrentBuffer(), start);
    data.set(arr3.getCurrentBuffer(), newend);

    // clip = acx.createBuffer(1, data.length, acx.sampleRate);
    // clip.copyToChannel(data, 0, 0);
    wvContainer.setSelection(newselection, acx.sampleRate);

    let newAudioData = new BufferedArray(data.length, data);
    waveMap.get(selectedClipId).audioData = newAudioData;
    this.setState({
      clip,
      audioData: newAudioData
    });

    this.setRecordingDuration();
    // wvContainer.updateEnd(Math.max(tempEnd, this.getLongestAudioDataLength()));

  }

  getRawAudioData() {
    const {audioData, waveMap} = this.state;
    // if current audioData exist, return it.
    if (audioData?.length){
      return audioData;
    } // if current audioData doesn't exist, return the last audioData in the audioData array or an empty array.
    return waveMap?.length > 0 ? Array.from(waveMap)[waveMap.size - 1].audioData : new BufferedArray(0);
  }

  toggleTool() {
    if(this.state.rightMenuOpen === false){
      this.setState({rightMenuOpen:true});
    } else {
      this.setState({rightMenuOpen:false});
    }

    return null;
  }

  getTotalSamplePoints() {
    if (this.getRawAudioData()) {
      return this.getLongestAudioDataLength();
    }

    return null;
  }

  getRecordingSamplePoints() { 
    if(this.state.recording && this.getRawAudioData()){
      return this.getAudioDataLength();
    }
    return null;
  }

  deleteClip() {
    const {selectedClipId, waveMap} = this.state;
    console.log(selectedClipId)
    if(selectedClipId !== null){
      waveMap.delete(selectedClipId);
    }else{
      waveMap.clear();
    }
    this.setState({
      selectedClipId: null,
      selectedClipIndex: null,
    });
  }

  deleteStoredAudioData() {
    this.setState(state => {
      state.audioData = [];
      return state;
    });
  }

  async toggleRecording(start) {
    const { acx, recording, mic, selectionPlayers, waveMap, recordingClipId } = this.state;
    await acx.resume(); // new Chrome policy https://goo.gl/7K7WLu

    // if recording is already on, stop the recording and push buffer to waveMap and return
    if (recording) {
      this.stop(); // stop recording
      await this.setState(state => {
        state.inputNode.gain.value = 0;
        state.recordingClipId = null; 
        return state;
      });
      // set the recording duration before returning
      this.setRecordingDuration();
      // add new wave object to waveMap, or update current wave
      // TODO: save xPos and yPos here 
      let audioData = this.state.audioData;

      if( recordingClipId === null ){
        let newWave = new Wave(audioData);
        waveMap.set(newWave.id, newWave);
        this.setSelectedClipId(newWave.id);
      }else{
        waveMap.get(recordingClipId).audioData = audioData;
      }
      UNSTATED.logState(); // debug the state on every recording stop
      return;
    }

    // if there are players being played currently, create a new recording by deleting stored audio buffers
    if (selectionPlayers.length > 0) {
      this.deleteStoredAudioData();
    }

    if (mic.permission !== MicStateEnum.ENABLED) {
      await this.enableMic();
    }

    // asynchronously update the state
    await this.setState(state => {
      state.inputNode.gain.value = 1;
      return state;
    });

    // reset the recording duration
    await this.resetRecordingDuration();
    
    if (this.state.mic.permission === MicStateEnum.ENABLED) {
      if(this.getSelectedClipId() === null && this.getWaveMapSize() < Math.floor(MAX_NUM_WAVES)){
        this.startNewClip();
        console.log("starting at", start)
        if(start === 0) {
          this.wvContainer.skipToStart(this.getTotalSamplePoints());
        }
        await this.fillEmptyAudioData(start);
      }else{
        const { selectedClipId, audioData } = this.getSelectedClipAndData();
        console.log("selected clip id", selectedClipId)
        if(selectedClipId !== null) {
          this.setState({ 
            audioData,
            recordingClipId: selectedClipId,
           });
        } else {
          const audioData = this.getLastAudioData();
          const clipId = this.getLastClipId();
          this.setState({ 
            audioData,
            recordingClipId: clipId,
           });
        }
        
      }

      // TODO: Hacky workaround to line up record and play: 200 ms delay in sound heard
      await setTimeout(async () => {
        await this.record();
      }, 200)
      // await this.record(); // call the record function after setting up to record
    }
  }

  appendPlayInterval(playInterval) {
    this.setState(state => {
      state.playInterval.push(playInterval);
      return state;
    });
  }

  /**
   * @name restoreSnapshot()
   * @description restore the waveMap from a snapshot
   */
  async restoreSnapshot(snapshot, wvContainer){
    // Deep copy waveMap
    const newWaveMap = new Map();
    snapshot.waveMap.forEach(item => {
      newWaveMap.set(item.id, new Wave(item.audioData.copy(),0,0, item.id));
    })

    await this.setState({
      waveMap: newWaveMap,
      selectedClipId: snapshot.selectedClipId,
      selectedClipIndex: snapshot.selectedClipIndex,
    })

    this.setRecordingDuration();
    wvContainer.updateEnd(this.getLongestAudioDataLength());

    // Reset speed and amplitude settings
    this.resetResample();
    this.resetAmplitude();

  }

/*--------------------------------Swipe Feature--------------------------------*/
  // experimental features with swipe feature
  mousedown(e, start, scale, simulate, cv) {
    this.selectClip(e.clientX, e.clientY, cv);
  }

  mousemove(e, start, scale, simulate) {
    if (simulate) return;
    const i = parseInt(start + e.nativeEvent.offsetX / scale, 10);

    if (this.state.start === 0) {
      this.state.start = i;
      return;
    }

  }

  touchdown(e, start, scale, simulate) {
    if (simulate) {
      var timesRun = 0;
      var interval = setInterval(() => {
        timesRun += 1;
        if (timesRun === 1000 / intervalForCheckingMouse) {
          // play one second worth of audio
          clearInterval(interval);
        }
      }, intervalForCheckingMouse);
    } else {
      // const i = start + e.nativeEvent.offsetX / scale;
    }
  }

  touchmove(e, start, scale, simulate, width) {
    if (simulate) return;
    const i = parseInt(
      start + (e.touches[0].clientX - (window.innerWidth - width) / 2) / scale,
      10
    );

    if (this.state.start === 0) {
      this.state.start = i;
      return;
    }

  }

  //get the audio buffer but process to make it last 50ms
  getBufferResample(start, end) {
    let { clip, acx } = this.state;

    return new Promise((resolve, reject) => {
      if (!clip) clip = this.createClip();

      start = start || 0;
      end = end || clip.length;

      let data;
      if (end > start) {
        data = clip.getChannelData(0).slice(start, end);
      } else {
        data = clip.getChannelData(0).slice(end, start);
      }
      // const buf = acx.createBuffer(1, end - start, acx.sampleRate);

      // convert data from length (end-start) to length (50 / 1000) * acx.sampleRate
      let fromLength = Math.abs(end - start);
      let toLength = (intervalForCheckingMouse / 1000) * acx.sampleRate;

      let toSampleRate = (acx.sampleRate * toLength) / fromLength;

      if (toSampleRate < 3000) {
        // console.log('low');
        toSampleRate = 3000;
      }
      const buf = acx.createBuffer(
        1,
        (intervalForCheckingMouse / 1000) * acx.sampleRate,
        acx.sampleRate
      );

      var resampler = new Resampler(
        acx.sampleRate,
        (acx.sampleRate * toLength) / fromLength,
        1,
        fromLength
      );

      var toArray = resampler.resample(data);

      // console.log(toArray);
      if (toArray.length !== 0) {
        buf.copyToChannel(toArray, 0, 0);
      }

      this.setState({
        clip: clip
      });

      return resolve(buf);
    });
  }

  changeSpeed(e) {
    e.preventDefault();

    let speed = e.target.value;

    this.setState({
      speed: speed
    });
  }
/*-----------------------------------------------------------------------------*/


/*----------------------------------Copy/Paste----------------------------------*/

  /**
   copy the selected clip and set the clipboard to the given data
   */
  copySelection(start = 0, end){
    // let { clip } = this.state;
    const { audioData } = this.getSelectedClipAndData();
    start = Math.min(Math.floor(start), audioData.length);
    end = end || audioData.length;
    end = Math.min(Math.floor(end), audioData.length);

    let data = audioData.getCurrentBuffer();
    let toBeSaved = data.slice(start, end);
    this.setClipboard(toBeSaved);
  }

  /**
   * @description paste the clipboard data to replace the selection
   */
  pasteToSelection(start, end, wvContainer){
    if (this.getSelectedClipId() === null) {
      this.pasteToNewTrack(start, wvContainer);
      return;
    }

    const { audioData } = this.getSelectedClipAndData();

    start = start || audioData.length;
    end = end || audioData.length;
    start = Math.min(Math.floor(start), audioData.length);
    end = Math.min(Math.floor(end), audioData.length);

    // if clipboard empty, do nothing; else, replace selection with clipboard data
    if (this.getClipboard().length === 0) {
      console.log('Clipboard empty, no action performed. ');
    } else {
      this.replaceSelection(start, end, this.getClipboard(), wvContainer);
    }
  }

  /**
   * If clicking paste with no track selected, paste to a new track if available
   * @param {*} start Start position to paste a new track
   * @param {*} wvContainer WaveViewerContainer
   */
  async pasteToNewTrack(start, wvContainer){
    start = Math.floor(start);
    let { waveMap, clip } = this.state;

    const clipboard = this.getClipboard();
    
    // generate new data array
    let data = new Float32Array(start + clipboard.length);
    data.set(new Array(start).fill(0.0));
    data.set(clipboard, start);

    // save data to clip and channel
    // clip = acx.createBuffer(1, data.length, acx.sampleRate);
    // clip.copyToChannel(data, 0, 0);

    // let newAudioData = Array.from(data)
    let newWave = new Wave(new BufferedArray(data.length,data));
    waveMap.set(newWave.id, newWave);

    this.setRecordingDuration();
    wvContainer.updateEnd(this.getLongestAudioDataLength());

    await this.setState({
      clip,
      audioData: newWave.audioData,
      selectedClipId: newWave.id,
      selectedClipIndex: waveMap.size - 1,
    });

  }

  /**
   paste the clipboard data to overlap with the selection
   */
  overlapOnSelection(start, end, wvContainer){
    let { acx } = this.state;
    let { waveMap, selectedClipId, audioData } = this.getSelectedClipAndData();

    end = end || audioData.length

    const clipToOverlap = this.getClipboard();

    // if clipboard empty, do nothing; else, overlap selection with clipboard data
    if(clipToOverlap.length === 0){
      console.log("Clipboard empty, no action performed. ");
      return;
    }

    // validate selection input
    if(start === null || Math.min(start, end) < 0 || Math.max(start, end) > audioData.length || start > end){
      console.error("Invalid input. Total length: " + audioData.length + ", Selection start: " + start + ", Selection end: " + end);
      return null;
    }
    start = Math.min(Math.floor(start), audioData.length);
    end = Math.min(Math.floor(end), audioData.length, Math.floor(start)+clipToOverlap.length);
    
    // generate new data array
    const arr1 = audioData.slice(0, start);
    const arr2 = audioData.slice(start, end);
    const arr3 = audioData.slice(end);

    // add up arr2 and clipToOverlap element-wise
    const newArr = [];
    for(let i = 0; i < arr2.length; i++){
      newArr.push(arr2[i]+clipToOverlap[i]);
    }

    let data = new Float32Array(arr1.length + arr2.length + arr3.length);
    data.set(arr1);
    data.set(newArr, start);
    data.set(arr3, arr1.length+arr2.length);

    let newAudioData = Array.from(data)
    waveMap.get(selectedClipId).audioData = newAudioData;

    // Set the new selection to the pasted part
    let newSelection = {
      start,
      end: start + arr2.length
    };

    this.setRecordingDuration();

    wvContainer.setSelection(newSelection, acx.sampleRate);
    wvContainer.updateEnd(this.getLongestAudioDataLength());
    wvContainer.setPointSelectionFlag(false);

    this.setState({
      audioData: newAudioData,
    });

  }
/*------------------------------------------------------------------------------*/


  /*--------------------------------Apply Effects--------------------------------*/

  /**
   * @name reverse()
   * @description apply reverse effect to the clip portion from start to end.
  */
  reverse(start, end) {
    let { clip, waveMap, selectedClipId, audioData } = this.getSelectedClipAndData();
    if(clip === null) return;
    const data = audioData;

    start = Math.floor(start < audioData.length? start : 0);
    end = Math.min(Math.floor(end || clip.length), clip.length);
    // if the selection is too small, assume it is a click, thus reverse the whole clip
    if (Math.abs(end - start) < 5) {
      start = 0; 
      end = clip.length;
    }
    // reverse inplace
    data.slice(start,end).reverse();
    // Don't reverse the zero-empty recording at the beginning of tracks 
    // TODO: Manually looking for the starting point of the recording for now. In the future, this should be a x-pos parameter in the Wave object
    let index = data.findIndex(val => val !== 0.0);
    start = Math.min(Math.max(index, start), end);

    waveMap.get(selectedClipId).audioData = data;

    this.setState({
      clip,
      audioData: data,
    });
  }

  // change the amplitude from start to end
  changeAmplitude(value, wvContainer, start, end) {
    let { clip, waveMap, selectedClipId, audioData } = this.getSelectedClipAndData()

    this.resetResample();
    // let { acx } = this.state;
    if (!clip) return;
    let data;
    start = start || 0;
    end = end || clip.length;
    start = Math.floor(start);
    end = Math.floor(end);
    if (this.originalAmplitudeData == null) {
      data = audioData;
      this.originalAmplitudeData = audioData.copy();      
    } else {
      data = this.originalAmplitudeData.copy();
    }

    data.slice(start, end).map(e => {
      return e * value;
    });

    // clip = acx.createBuffer(1, data.length, acx.sampleRate);
    // clip.copyToChannel(data.getCurrentBuffer(), 0, 0);
    // let newAudioData = Array.from(data)
    waveMap.get(selectedClipId).audioData = data;

    this.setState({
      clip,
      audioData : data,
    });
  }

  /**
 * Repeat a selection multiple times and add it at the end of the selected segment
 * @param {*} start Beginning of the clip to be repeated. Default: clip.length.
 * @param {*} end End of the clip to be repeated. Default: clip.length.
 * @param {*} wvContainer waveViewerContainer for wave visual functions.
 * @param {*} times Number of times to repeat the segment. 
 */
  async repeatSelection(start, end, wvContainer, times){
    let { clip,audioData } = this.getSelectedClipAndData()

    let { acx } = this.state;

    start = Math.min(Math.floor(start), clip.length);
    end = end || clip.length;
    end = Math.min(Math.floor(end), clip.length);

    let data = audioData.getCurrentBuffer();

    let toRepeat = data.slice(start, end);

    for(let i = 0; i < times; i++){
      await this.replaceSelection(end+toRepeat.length*i, end+toRepeat.length*i, toRepeat, wvContainer);
    }

    // update selection
    let newSelection = {
      start: start,
      end: end+toRepeat.length*(times)
    }
    wvContainer.setSelection(newSelection, acx.sampleRate);
  }
  /*-----------------------------------------------------------------------------*/


  /*-----------------------------------REVERB-----------------------------------*/

  async createReverb() {
    //const { reverbNode, acx } = this.state;
    // load impulse response from file
    /*
    const response = await fetch(
      'https://soundbasketlisten.s3-us-west-1.amazonaws.com/rir_jack_lyons_lp2_96k.wav'
    );
    const arraybuffer = await response.arrayBuffer();
    reverbNode.buffer = await acx.decodeAudioData(arraybuffer);
    */
  }

  async toggleReverb() {
    // toggle state
    await this.setState(state => {
      // reverb was off
      if (!state.isReverbOn && state.recording) {
        this.connectReverbNode();
      } else if (state.recording) {
        this.disconnectReverbNode();
      }
      state.isReverbOn = !state.isReverbOn;
      return state;
    });
  }

  connectReverbNode() {
    const { inputNode, reverbGainNode, reverbNode, processor } = this.state;
    inputNode.disconnect(processor);
    inputNode.connect(reverbNode);
    reverbNode.connect(reverbGainNode);
    reverbGainNode.connect(processor);
  }

  disconnectReverbNode() {
    const { inputNode, reverbGainNode, reverbNode, processor } = this.state;
    inputNode.disconnect(reverbNode);
    reverbNode.disconnect(reverbGainNode);
    reverbGainNode.disconnect(processor);
    inputNode.connect(processor);
  }

  async applyEffect(offlineCtx, reverb, selection) {
    console.log('apply effect')

    // get buffer
    const source = offlineCtx.createBufferSource();
    if (selection.start === selection.end) {
      // 0 0 for entire track
      source.buffer = await this.getBuffer(0, 0);
    } else {
      source.buffer = await this.getBuffer(selection.start, selection.end);
    }
    source.connect(reverb);
    source.start();

    // render to get result
    const renderedBuffer = (await offlineCtx.startRendering()).getChannelData(0);
    const renderedAudioData = renderedBuffer;

    // update buffer with effect applied
    this.setState(state => {
      // update the entire buffer if no selection
      if (selection.start === selection.end) {
        state.audioData = new BufferedArray(renderedAudioData.length, renderedAudioData);
      } else {
        // find indices to replace
        let start = Math.max(selection.start, 0);
        let end = Math.min(selection.end, this.getAudioDataLength());
        state.audioData = state.audioData.map((data, i) => {
          if (i >= start && i <= end && renderedAudioData[i - start]) {
            data = renderedAudioData[i - start];
          }
          return data;
        });
      }
      return state;
    });
  }
  /*----------------------------------------------------------------------------*/


  /*---------------------------Private Helper Functions---------------------------*/
  /**
   * @name fillAudioData
   * @description Updates the audioData as the audio recording progresses.
   * @param buf New buffer to be appended to the audioData array
   * Effects: Upon recording, the audio context updates the audioData array in
   *          state by using onaudioprocess event
   */
  async fillAudioData(buf) {
    await this.setState(state => {
      state.audioData = state.audioData.concat(Array.from(buf));
      return state;
    });
  }

  /**
   * @description Helper function to fills empty audioData before the audio recording starts
   * @param sample sample points need to be filled, could be obtained from wvContainer.getSelection() 
   */
  async fillEmptyAudioData(sample) {
    await this.setState(state => {
      state.audioData = new BufferedArray(Math.floor(sample)).fillCap(0.0);
      return state;
    });
  }

  /**
   * @name createClip()
   * @description converts saved audioData into single AudioBuffer object
   * Effects: Saves the complete AudioBuffer object into clip
   */
  createClip(audioData=null) {
    if (!audioData){
      audioData = this.state.audioData;
    }
    // console.log(audioData);
    const { acx } = this.state;
    if (!audioData.length) return null;

    const length = audioData.length;

    const clip = acx.createBuffer(1, length, acx.sampleRate);
    // const data = new Float32Array(audioData.length);
    // data.set(audioData);
    clip.copyToChannel(audioData.getCurrentBuffer(), 0, 0);
    return clip;
  }

  /* Helper functions for recording, stop */
  async record() {
    await this.setState(state => {
      if (!state.processor) {
        // eslint-disable-next-line no-param-reassign
        state.processor = state.acx.createScriptProcessor(undefined, 1, 1);
      }
      /** connect nodes based on effects applied */
      if (state.isReverbOn) {
        state.inputNode.connect(state.reverbNode);
        state.reverbNode.connect(state.reverbGainNode);
        state.reverbGainNode.connect(state.processor);
      } else {
        state.inputNode.connect(state.processor);
      }

      state.processor.connect(state.acx.destination);

      /* Setup callback on the processor to fill in buffers asynchronously when recording starts */
      state.processor.onaudioprocess = event => {
        const buf = event.inputBuffer.getChannelData(0).slice();
        this.fillAudioData(buf).then(() => {
          // make sure the updated buffers update the waveviewer's container now
          if (this.getAudioDataLength()) {
            // let newAudioDataLength = this.getLongestAudioDataLength();
            this.wvContainer.updateEnd(this.getAudioDataLength());
          }
        });
      };
      /* toggle recording flag to true */
      state.recording = true;
      return state;
    });
  }

  stop() {
    this.setState(state => {
      if (state.isReverbOn) {
        state.processor.disconnect();
        state.inputNode.disconnect(state.reverbNode);
        state.reverbNode.disconnect(state.reverbGainNode);
        state.reverbGainNode.disconnect(state.processor);
      } else {
        state.processor.disconnect();
        state.inputNode.disconnect(state.processor);
      }
      state.recording = false;
      state.hasRecorded = true;
      state.processor = null;
      return state;
    });
  }

  /**
 * @name enableMic()
 * @description connects the mic to enable the app
 * @returns if mic was succesfully enabled
 * Effects: Modifies this.mic
 */
  async enableMic() {
    try {
      // get mic stream and connect to input node
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.setState(state => {
        state.recordingstream = stream;
        state.mic.permission = MicStateEnum.ENABLED;
        state.mic.stream = state.acx.createMediaStreamSource(stream);
        state.mic.stream.connect(state.inputNode);
        return state;
      });
    } catch (err) {
      this.setState(state => {
        state.mic.permission = MicStateEnum.DENIED;
        return state;
      });
    }
  }

  /**
    @description return the selected clip and the corresponding audioData in waveMap
  */
  getSelectedClipAndData(){
    const selectedClipId = this.getSelectedClipId();
    const { waveMap } = this.state;
    if(!waveMap.has(selectedClipId)) {
      return { clip: null, waveMap: null, selectedClipId: null, audioData: null};
    }
    let audioData = waveMap.get(selectedClipId).audioData;
    let clip = audioData;

    return { clip, waveMap, selectedClipId, audioData};
  }

  // Fire at mouse down. according to the position of the mouse click, set the nearest audio track
  selectClip(x, y, cv){
    //TODO: different selection algorithm in the future
    const rect = cv.getBoundingClientRect();
    const selectionHeight = cv.height/(0.9*MAX_NUM_WAVES);
    console.log("Select clip ", y ,rect, cv.height)
    y = y - rect.top;
    console.log("Selecting clip ",y, selectionHeight)
    const selectedClipIndex = Math.floor(y / selectionHeight);
    const selectedClipId = (selectedClipIndex < this.getWaveMapSize() && selectedClipIndex >= 0) ? Array.from(this.state.waveMap)[selectedClipIndex][0] : null;
    // this.state.selectedClipId = selectedClipId;
    // this.state.selectedClipIndex = selectedClipIndex;
    this.setState({
      selectedClipId,
      selectedClipIndex,
    });
  }

  setSelectedClipId(id){
    this.setState({
      selectedClipId: id
    })
  }

  /**
   * Helper function, Replace the selection part with the new replacement array
   * @param {Number} start Beginning of the clip to be replaced
   * @param {Number} end End of the clip to be replaced
   * @param {Array} replacement Array of clip to replace the selected part
   * @param {*} wvContainer waveViewerContainer for wave visual functions
   * @returns {Object} {begin index of the replacement, end index of the replacement, the original selection data array}
   * @returns nothing if there's an error
   */
  replaceSelection(start, end, replacement = [], wvContainer, replaceAll = false, moveEnd=true){
    let { clip, waveMap, selectedClipId, audioData } = this.getSelectedClipAndData()
    let { acx } = this.state;
    let data = audioData;
    
    if(selectedClipId === null) {
      return;
    }

    // validate selection input
    if(replaceAll){
      start = 0;
      end = audioData.length;
    }else if(start === null || end === null || Math.min(start, end) < 0 || Math.max(start, end) > audioData.length || start > end){
      console.error("Invalid input. Total length: " + audioData.length  + ", Selection start: " + start + ", Selection end: " + end + ", Replacement: " + replacement);
      return null;
    }

    start = Math.floor(start);
    end = Math.floor(end);

    // generate new data array
    const arr1 = data.slice(0, start).getCurrentBuffer();
    const arr2 = replacement;
    const arr3 = data.slice(end).getCurrentBuffer();
    const replacedArr = data.slice(start, end);

    data = new Float32Array(arr1.length + arr2.length + arr3.length);
    data.set(arr1);
    data.set(arr2, start);
    data.set(arr3, arr1.length+arr2.length);

    let newAudioData = new BufferedArray(data.length, data);
    waveMap.get(selectedClipId).audioData = newAudioData;

    // Set the new selection to the pasted part
    let newSelection = {
      start,
      end: start + arr2.length
    }

    this.setRecordingDuration();

    if(!wvContainer.getPointSelectionFlag() && end - start <= 1){
      wvContainer.setSelection(newSelection, acx.sampleRate);
    }

    if(moveEnd) {
      wvContainer.updateEnd(this.getLongestAudioDataLength());
    }
    
    // wvContainer.setPointSelectionFlag(false);

    this.setState({
      clip,
      audioData: newAudioData,
    });

    return {
      start,
      end: arr2.length,
      replacedArr
    };
  }

  moveLeftWithoutCutting(moveLength, wvContainer) {
    let { waveMap, selectedClipId, audioData } = this.getSelectedClipAndData()
    let index = audioData.findIndex(val => val !== 0.0);
    let removeLen = Math.min(-moveLength, index);
    this.replaceSelection(0,removeLen,[],wvContainer);
    let remainingLen = -moveLength - removeLen;
    if(remainingLen > 0) {
      const bufferArray = new Float32Array(remainingLen).fill(0);
      this.moveEverythingExceptSelection(bufferArray, waveMap, selectedClipId)
    }
  }

  moveEverythingExceptSelection(replaceMent, waveMap, selectedClipId) {
    let newWaveMap = new Map();
    waveMap.forEach((v,k) => {
      if(k !== selectedClipId) {
        let newAudData = new Float32Array(v.audioData.length + replaceMent.length)
        newAudData.set(replaceMent)
        newAudData.set(v.audioData.getCurrentBuffer(),replaceMent.length)
        newWaveMap.set(k, new Wave(new BufferedArray(newAudData.length, newAudData),0,0, v.id));
        // newWaveMap.get(k).audioData = 
      } else {
        newWaveMap.set(k,v)
      }
    })
    this.setState({waveMap: newWaveMap})
  }
  /*------------------------------------------------------------------------------*/


  /*-----------------------------Move Recording-----------------------------------*/
  /**
   * Moves the whole recording to the left or right. For multiwave version. 
   * @param {int} moveLength The length we want to move the whole recording for.
   *  Negative means moving to the left, positive means moving to the right.
   * @param {*} wvContainer 
   */
  moveRecording(moveLength, wvContainer) {
    // console.log(this.state.clip.getChannelData(0).length)
    if (moveLength > 0){
      // For moving right, plug empty array at the beginning
      const bufferArray = new Float32Array(moveLength).fill(0);
      this.replaceSelection(0, 0, bufferArray, wvContainer, false, false);
    }else if (moveLength < 0){
      // For moving left, simply remove array at the beginning for now
      this.moveLeftWithoutCutting(moveLength,wvContainer);
      // this.replaceSelection(0, -moveLength, [], wvContainer);
    }
    // console.log(this.state.clip.getChannelData(0).length)
  }
  /*------------------------------------------------------------------------------*/


  /*----------------------------MP3 Upload Functions------------------------------*/
  
  /**
   * Decode the downloaded ArrayBuffer from Firebase into a compatible buffer for Tone.
   * @param {ArrayBuffer} audioData The audio file data downloaded directly from Firebase 
   * @returns The decoded audio data in Float32Array format
   */
  async decodeArrayBuffer(audioFile){
    // decode arrayBuffer using Tone's pre-defined decoder, then extract channel data
    let audioData = this.state.acx.decodeAudioData(audioFile).then(data => data.getChannelData(0));
    return audioData;
  }

  /**
   * Upload a buffer to the list of waves. Useful for uploading MP3 file.
   * @param {*} audioData The audio data to be added as a new wave 
   */
  uploadAudioData(audioData){
    if (this.getWaveMapSize() + 1 > MAX_NUM_WAVES) {
      let lastKeyInMap = Array.from(this.state.waveMap.keys()).pop();
      let waveData = this.state.waveMap.get(lastKeyInMap);
      waveData.audioData = waveData.audioData.concat(audioData);
      const waveMap = new Map(this.state.waveMap);
      this.setState({waveMap: waveMap})
    } else {
      let buf = new BufferedArray(audioData.length, audioData);
      let newWave = new Wave(buf);
      this.state.waveMap.set(newWave.id, newWave);
    }

  }
  
    /**
     * @deprecated The buffer[][] data structure is replaced with audioData[] in new version
     * Convert a data array into an array of specified-length Float32Arrays. For this app, 
     *  the buffer is an array of Float32Array(2048). Can replace the already-existed buffer 
     *  loops in other functions. 
     * @param {Float32Array} buf Original buffer Float32Array
     * @param {int} bufLen Length of 1 buffer clip
     * @return The converted buffer
     */
    sliceBuffer(buf, bufLen=BUFFER_CLIP_LENGTH){
      let buffers = [];
  
      let startIdx = 0;
      let endIdx = bufLen;
  
      while(endIdx < buf.length){
        buffers.push(buf.slice(startIdx, endIdx));
  
        startIdx += bufLen;
        endIdx += bufLen;
      }
  
      return buffers;    
    }

  /*------------------------------------------------------------------------------*/

  /*--------------------Setter, Getter, etc for State variables--------------------*/

  /**
   * @name setWaveViewer
   * @description Gives access to Wave Viewer's container in this container
   * @param {Container} wvContainer A reference to the wv container
   */
  setWaveViewer(wvContainer) {
    this.wvContainer = wvContainer;
  }

  getResetResampleDisplay() {
    return this.state.resetResampleDisplay;
  }

  setResetResampleDisplay(e) {
    this.setState({
      resetResampleDisplay: e
    });
  }

  getResetAmplitudeDisplay() {
    return this.state.resetAmplitudeDisplay;
  }

  setResetAmplitudeDisplay(e) {
    this.setState({
      resetAmplitudeDisplay: e
    });
  }

  getResetLengthDisplay() {
    return this.state.resetLengthDisplay;
  }

  setResetLengthDisplay(e) {
    this.setState({
      resetLengthDisplay: e
    });
  }

  getAmplitudeRatio() {
    return this.state.amplitudeRatio;
  }

  setAmplitudeRatio(value) {
    this.setState({
      amplitudeRatio: value
    });
  }

  getResampleRatio() {
    return this.state.resampleRatio;
  }

  setResampleRatio(value) {
    this.setState({
      resampleRatio: value
    });
  }

  resetResample() {
    this.setState({
      resampleRatio: 1000,
      resetResampleDisplay: true
    });
    this.originalSpeedData = null;
  }

  resetAmplitude() {
    this.setState({
      amplitudeRatio: 2000,
      resetAmplitudeDisplay: true
    });
    this.originalAmplitudeData = null;
  }
  setOutputVolume(newVolume) {
    this.setState({
      outputVolume: newVolume
    });
  }


  setRecordingDuration() {
    // get the duration of the recording after the user hits stop
    this.getBuffer().then(audioBuffer => {
      const bufDuration = audioBuffer.duration * 1000;
      // set the duration in milliseconds in the state
      this.setState({
        recordingDuration: bufDuration
      });
    });
  }

  async resetRecordingDuration() {
    await this.setState({
      recordingDuration: 0
    });
  }
  getContext() {
    return this.state.acx;
  }

  getInputGain() {
    return this.state.inputNode.gain.value;
  }

  isRecording() {
    return this.state.recording;
  }

  isRightMenuOpen() {
    return this.state.rightMenuOpen;
  }

  hasRecorded() {
    return this.state.hasRecorded;
  }

  setInputGain(newGain) {
    /* Function that updates the gain of the inputNode that records the user audio */
    this.setState(state => {
      state.inputNode.gain.value = newGain;
      return state;
    });
  }

  getMonitorGain() {
    return this.state.monitorNode.gain.value;
  }

  setMonitorGain(newGain) {
    this.setState(state => {
      state.monitorNode.gain.value = newGain;
      return state;
    });
  }

  connectMonitorNode(destination) {
    this.setState(state => {
      state.monitorNode.connect(destination);
      return state;
    });
  }

  disconnectMonitorNode(destination) {
    this.setState(state => {
      state.monitorNode.disconnect(destination);
      return state;
    });
  }

  /**
   * @name updateMicPermissions
   * @description Setter: this.state.mic.permission. Sets the mic permission to one of the three: enabled, disabled and denied
   * @param {MicStateEnum} newPermission
   */
  setMicPermission(newPermission) {
    this.setState(state => {
      state.mic.permission = newPermission;
      return state;
    });
  }

  /**
   * @name getMicPermission
   * @description Getter: this.state.mic.permission. Gets the permission of the mic
   */
  getMicPermission() {
    return this.state.mic.permission;
  }

  getSampleRate() {
    return this.state.acx.sampleRate;
  }

    /**
   * Save a snippet of recording into clipboard for future use
   * Useful for copying and pasting
   * @param {array} data The array of data to be saved to clipboard
   */
    setClipboard(data){
      this.setState({
        clipboard: data,
      })
    }

    /**
     * Gets the snippet of recording in the clipboard
     * Useful for copying and pasting
     * @returns The array of data in the clipboard
     */
    getClipboard(){
      return this.state.clipboard;
    }

    getSelectedClipId(){
      return this.state.selectedClipId;
    }

    getSelectedClipIndex(){
      return this.state.selectedClipIndex;
    }

    getRecordingClipId(){
      return this.state.recordingClipId;
    }

    getWaveMapSize() {
      return this.state.waveMap.size;
    }

    /**
     * Get the audio data of last wave inserted
     * @returns audio data of last wave, or empty array if there's no wave available
     */
    getLastAudioData(){
      const { waveMap } = this.state;
      // Array.from(Map)[index] gives a [key, value] pair
      return waveMap.size > 0 ? Array.from(waveMap)[waveMap.size - 1][1].audioData : []; 
    }

    /**
     * Get the last clip id in wavemap
     * @returns clipid of last wave map
     */
    getLastClipId(){
      const { waveMap } = this.state;
      // Array.from(Map)[index] gives a [key, value] pair
      return waveMap.size > 0 ? Array.from(waveMap)[waveMap.size - 1][0] : 0; 
    }

    getSelectedClipLength() {
      const {selectedClipId,waveMap} = this.state;
      if(selectedClipId) {
        return waveMap.get(selectedClipId).audioData.length;
      }
      return this.getLongestAudioDataLength();
    }
  /*-------------------------------------------------------------------------------*/
}

export default AudioRecorderContainer;
